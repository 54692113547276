import {types} from 'mobx-state-tree';

import Flex_1_1 from './../../../assets/images/flex/flex_1_1.png';
import Flex_1_2 from './../../../assets/images/flex/flex_1_2.png';
import Flex_2_1 from './../../../assets/images/flex/flex_2_1.png';
import Flex_2_2 from './../../../assets/images/flex/flex_2_2.png';
import Flex_3_1 from './../../../assets/images/flex/flex_3_1.png';
import Flex_3_2 from './../../../assets/images/flex/flex_3_2.png';

const Flexibility = types.model({
  id: types.optional(types.string, ''),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
})

export const flexibilityList = [
  {
    id: '3',
    title: 'Хорошая гибкость',
    image1: Flex_1_1,
    image2: Flex_1_2,
  },
  {
    id: '2',
    title: 'Средняя гибкость',
    image1: Flex_2_1,
    image2: Flex_2_2,
  },
  {
    id: '1',
    title: 'Плохая гибкость',
    image1: Flex_3_1,
    image2: Flex_3_2,
  },
];

export const FlexibilityStep = types.model({
  selectedFlexibility: types.optional(types.maybeNull(Flexibility), null),
  flexibilityList: types.optional(types.array(Flexibility), [])
}).actions(self => {

  function loadflexibilityList(callback) {
    callback([
      {
        id: '3',
        title: 'Хорошая гибкость',
        image1: Flex_1_1,
        image2: Flex_1_2,
      },
      {
        id: '2',
        title: 'Средняя гибкость',
        image1: Flex_2_1,
        image2: Flex_2_2,
      },
      {
        id: '1',
        title: 'Плохая гибкость',
        image1: Flex_3_1,
        image2: Flex_3_2,
      },
    ])
  }

  function init() {
    loadflexibilityList((data) => {
      self.flexibilityList = data;
    })
  }

  function setFlexibility(FlexibilityId) {
    const foundItem = self.flexibilityList.filter((item) => item.id === FlexibilityId)[0];
    self.selectedFlexibility = Flexibility.create({...foundItem});

    if (self.callback) self.callback(self.selectedFlexibility);
  }

  function setCallback(callback) {
    self.callback = callback;
  }

  return {
    init,
    setFlexibility,
    setCallback
  }
});
