import {observer} from 'mobx-react';

import {
  AppButton,
  AppInput,
  StepNav
} from '../core';
import ArrowRight from "../../assets/images/arrow-right.svg";

function GenderStep({test}) {
  const {namestep, steplist} = test;

  const nextButtonDisabled = !(namestep.nameValid);

  const goToNextStep = () => {
    if (!nextButtonDisabled) {
      steplist.next();
    }
  }

  return (
    <div className="plain-step">
      <div className="plain-step__container">
        <div className="plain-step__title">
          Как зовут ребенка?
        </div>
        <div className="plain-step__field">
          <div className="field">
            <AppInput
              label="Имя"
              value={namestep.name}
              onChange={(newValue) => namestep.setName(newValue)}
            />
          </div>
        </div>
        <div className="plain-step__button">
          <AppButton
            buttonType="solid"
            disabled={nextButtonDisabled}
            text={<>Следующий шаг <img src={ArrowRight} alt=""/></>}
            onClick={goToNextStep}
          />
        </div>
      </div>
      <div className="plain-step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
    </div>
  );
}

export default observer(GenderStep);
