import {types} from 'mobx-state-tree';

export const PlumberTryItem = types.model({
  moves: types.optional(types.number, 0),
  duration: types.optional(types.number, 0),
})

export const PlumberTryList = types.model({
  max: types.optional(types.number, 3),
  items: types.optional(types.array(PlumberTryItem), []),
  bestTime: types.maybeNull(types.number), // Задано только когда состояние теста получено из API
}).actions(self => {

  function push(tryData) {
    self.items = [...self.items, tryData];
  }

  return {
    push
  }
}).views(self => ({
  get bestResult() {
    if (self.bestTime) {
      return {moves: 0, duration: self.bestTime};
    }

    let best = {moves: Infinity, duration: Infinity};

    if (self.items.length > 0) {
      self.items.forEach((tryItem) => {
        if (tryItem.duration + (tryItem.moves * 500) < best.duration + (best.moves * 500)) {
          best = tryItem;
        }
      });

      return best;
    } else {
      return null;
    }
  }
}))
