import axios from 'axios';


class FeedBackApiService {
  commonHeaders = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  sendFeedback = async ({ name, phone }) => {
    const requestData = {
      name,
      phone,
    };

    try {
      const url = process.env.REACT_APP_FEED_BACK_API;

      await axios.post(
        url,
        requestData,
        this.commonHeaders,
      );

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
}

export default FeedBackApiService;
