import { observer } from 'mobx-react';


import {
	AppButton,
	AppInput,
	StepNav
} from '../core';
import ArrowRight from "../../assets/images/arrow-right.svg";

function ParentsStep({test}) {

	const { parentsstep, steplist } = test;

	const nextButtonDisabled = !(parentsstep.stepValid);

	const goToNextStep = () => {
		if(!nextButtonDisabled) {
			steplist.next();
		}
	}

	return (
		<div className="plain-step">
			<div className="plain-step__container">
				<div className="plain-step__title">
					Какой рост у мамы и папы? {parentsstep.stepValid}
				</div>
				<div className="plain-step__field">
					<div className="field">
						<AppInput
							label="Рост мамы"
							value={parentsstep.motherHeight}
							inputType="number"
							onChange={(newValue) => parentsstep.setMotherHeight(newValue)}
						/>
					</div>
				</div>
				<div className="plain-step__field">
					<div className="field">
						<AppInput
							label="Рост папы"
							value={parentsstep.fatherHeight}
							inputType="number"
							onChange={(newValue) => parentsstep.setFatherHeight(newValue)}
						/>
					</div>
				</div>
				<div className="plain-step__button">
					<AppButton
						buttonType="solid"
						disabled={nextButtonDisabled}
						text={<>Следующий шаг <img src={ArrowRight} alt=""/></>}
						onClick={goToNextStep}
					/>
				</div>
			</div>
			<div className="plain-step__step-nav">
				<StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex} />
			</div>
		</div>
	);
}

export default observer(ParentsStep);
