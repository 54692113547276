import React from 'react';
import {useHistory} from "react-router-dom";
import {observer} from 'mobx-react';
import {AppButton} from "../core";
import {LK} from "../../routes";
import ReactPlayer from "react-player";

const FinalStep = ({test}) => {
    const history = useHistory();

    const onButtonClick = async () => {
        history.push(LK);
    }

    return (
        <div className="app app--system">
            <div className="finish-page-content">
                <div className="plain-step__title">Поздравляем, вы успешно прошли тест!</div>
                <div className="plain-step__video-wrapper">
                    <ReactPlayer
                        className="plain-step__video-player"
                        playing={true}
                        controls={true}
                        url='/videos/5-finish.mp4'
                        height="100%"
                        width="100%"
                    />
                </div>
                <p>
                    Мы очень надеемся, что результаты<br/>
                    тестирования будут вам полезны.
                    <br/><br/>
                    Успехов вам и вашему ребенку!
                </p>

                <div className="finish-game-button-content">
                    <AppButton
                        buttonType="outline"
                        text="Перейти в личный кабинет"
                        onClick={onButtonClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default observer(FinalStep);
