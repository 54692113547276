import {Transition} from 'react-transition-group';
import React from 'react';

const duration = 2500;

const FadeCountdown = ({in: inProp, children}) => (
  <Transition in={inProp} timeout={duration}>
    {state => (
      <div className={`countdown countdown-${state}`} >
        {children}
      </div>
    )}
  </Transition>
);

export default FadeCountdown;
