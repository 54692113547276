export const regularStepList = [
  {target: 'adult', cssClassSuffix: ' app--step-age'}, // 0 возраст
  {target: 'adult', cssClassSuffix: ' app--step-name'}, // 1 имя
  {target: 'adult', cssClassSuffix: ' app--step-parents'}, // 2 рост мамы и папы и так же заказ, если тест не куплен
  {target: 'adult', cssClassSuffix: ' app--step-gender'}, // 3 пол ребенка
  {target: 'adult', cssClassSuffix: ' app--step-constitution'}, // 4  тип телосложения
  {target: 'adult', cssClassSuffix: ' app--step-psychotype'}, // 5 психология

  {target: 'child', cssClassSuffix: ' app--step-fox-hello'}, // 6 знакомство с персонаженм

  {target: 'child'}, // 7 игра на задержку дыхания
  {target: 'child', cssClassSuffix: ' app--step-jump'}, // 8 прыжок
  {target: 'child', cssClassSuffix: ' app--step-coordination'}, // 9 координация
  {target: 'child', cssClassSuffix: ' app--step-elasticity'}, // 10 гибкость
  {target: 'child', cssClassSuffix: ' app--step-cns'}, // 11 игра с трубами
  {target: 'child', cssClassSuffix: ' app--step-reaction'}, // 12 игра с зайцами
  {target: 'adult', cssClassSuffix: ' app--step-final'}, // 13 финал
];

export const guestStepList = [
  {target: 'adult', cssClassSuffix: ' app--step-age'}, // 0 возраст
  {target: 'child'}, // 1 игра на задержку дыхания
  {target: 'adult', cssClassSuffix: ' app--step-name'}, // 2 имя
  {target: 'adult', cssClassSuffix: ' app--step-parents'}, // 3 рост мамы и папы и так же заказ, если тест не куплен
  {target: 'adult', cssClassSuffix: ' app--step-gender'}, // 4 пол ребенка
  {target: 'adult', cssClassSuffix: ' app--step-constitution'}, // 5 тип телосложения
  {target: 'adult', cssClassSuffix: ' app--step-psychotype'}, // 6 психология

  {target: 'child', cssClassSuffix: ' app--step-fox-hello'}, // 7 знакомство с персонаженм

  {target: 'child', cssClassSuffix: ' app--step-jump'}, // 8 прыжок
  {target: 'child', cssClassSuffix: ' app--step-coordination'}, // 9 координация
  {target: 'child', cssClassSuffix: ' app--step-elasticity'}, // 10 гибкость
  {target: 'child', cssClassSuffix: ' app--step-cns'}, // 11 игра с трубами
  {target: 'child', cssClassSuffix: ' app--step-reaction'}, // 12 игра с зайцами
  {target: 'adult', cssClassSuffix: ' app--step-final'}, // 13 финал
];
