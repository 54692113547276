import {useEffect} from 'react';
import {observer} from 'mobx-react';

import {
  AppButton,
  StepNav
} from '../core';

function GenderStep({test}) {

  const {genderstep, steplist} = test;

  useEffect(() => {
    genderstep.init();
    genderstep.setCallback(() => {
      steplist.next();
    });
  }, []);

  return (
    <div className="plain-step">
      <div className="plain-step__container">
        <div className="plain-step__title">
          Ребенок мальчик или девочка?
        </div>
        {
          genderstep.genders.map((gender, index) => {
            return (
              <div className="plain-step__button" key={index}>
                <AppButton
                  buttonType="solid"
                  text={gender.text}
                  onClick={() => genderstep.setGender(gender.id)}
                />
              </div>
            )
          })
        }
      </div>
      <div className="plain-step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
    </div>
  );
}

export default observer(GenderStep);
