import React, {useEffect, useState} from 'react';
import useCountDown from 'react-countdown-hook';
import FadeNumber from "./FadeNumber";
import FadeCountdown from "./FadeCountdown";

const COUNT_TO = 3;
const INTERVAL = 1000;

const Countdown = ({count, callback}) => {
  const [timeLeft, {start, reset}] = useCountDown(COUNT_TO * INTERVAL, INTERVAL);
  const [wasStarted, setWasStarted] = useState(false);

  useEffect(() => {
    if (count) {
      setWasStarted(true);
      start();
    } else {
      setWasStarted(false);
      reset();
    }
  }, [count, start, reset, setWasStarted]);

  useEffect(() => {
    if (timeLeft === 0 && count && wasStarted) {
      callback();
    }
  }, [timeLeft, callback, count, wasStarted]);

  if (!count) {
    return null;
  }

  return (
    <FadeCountdown in={timeLeft > 2 * INTERVAL}>
      <div className="number">
        <FadeNumber in={timeLeft === 3 * INTERVAL}>
          3
        </FadeNumber>
        <FadeNumber in={timeLeft === 2 * INTERVAL}>
          2
        </FadeNumber>
        <FadeNumber in={timeLeft === INTERVAL}>
          1
        </FadeNumber>
      </div>
    </FadeCountdown>
  );
}

export default Countdown;
