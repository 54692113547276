import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import './styles/index.scss';
import App from './components/app/App';
import { Provider, rootStore } from './models/root';
import updateJsVh from './utils/updateJsVh';

if (window.location.host === 'firststep.budsilnym.ru') {
	LogRocket.init('budsilnym/firststepbudsilnymru');
}

ReactDOM.render(
	<Provider value={rootStore}>
		<App />
	</Provider>,
  document.getElementById('root')
);

updateJsVh();
window.addEventListener('resize', () => {
  updateJsVh();
})
