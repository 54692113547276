import { types } from 'mobx-state-tree';
import { HareList } from './hare-list';


export const HareholeTryList = types.model({
	max: types.optional(types.number, 3),
	items: types.optional(types.array(HareList), []),
	bestTime: types.maybeNull(types.number), // Задано только когда состояние теста получено из API
}).actions(self => {

	function push(tryData) {
		self.items = [...self.items, {items: [...tryData.items.map((item) => {
			return {
				state: item.state,
				timeshown: item.timeshown,
				timeclicked: item.timeclicked
			}
		})]}];
	}

	return {
		push
	}
}).views(self => ({
	get bestResult() {
		if (self.bestTime) {
			return self.bestTime;
		}

		let best = Infinity;

		if(self.items.length > 0) {
			self.items.forEach((tryDataItem) => {
				tryDataItem.items.forEach((hare) => {
					if(hare.timeclicked && hare.timeshown && hare.timeclicked - hare.timeshown < best) {
						best = hare.timeclicked - hare.timeshown;
					}
				})
			});

			return best;
		} else {
			return null;
		}
	}
}))
