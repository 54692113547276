import React from 'react';
import {Link} from "react-router-dom";
import {ABOUT, PAYMENT_RULES, POLICY} from "../../../routes";
// import VkSVG from '../social-icons/vk.svg'
import WhatsupSVG from '../social-icons/whatsup.svg'
import InstaSVG from '../social-icons/insta.svg'


const Footer = () => {
  return (
    <div className="footer">
      <div className="block">
        <Link className="footer__link" to={ABOUT}>
          &copy;&nbsp;2023 &laquo;Сила Скилла&raquo;
        </Link>
        <Link className="footer__link" to={PAYMENT_RULES}>
          Правила оплаты
        </Link>
        <Link className="footer__link" to={POLICY}>
          Политика конфиденциальности
        </Link>
        {/*<a className="footer__link" href="#">*/}
        {/*  Мы&nbsp;в&nbsp;соц сетях*/}
        {/*</a>*/}
        {/*<a className="footer__link" href="tel:+73833830999">*/}
        {/*  +7 (383) 383-09-99*/}
        {/*</a>*/}
        <div>
          <a href="https://wa.me/message/RYG2BHW5A6DMM1" target="_blank" rel="noreferrer noopener">
            <img src={WhatsupSVG} alt="" width={30} height={31}/>
          </a>
          <a href="https://instagram.com/silaskilla?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer noopener">
            <img src={InstaSVG} alt="" width={30} height={31}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer;
