import React, {useState, useEffect, useCallback} from 'react';
import {useMediaQuery} from '@react-hook/media-query'

import ReactPlayer from "react-player";
import Countdown from "./Countdown";

const video = {
  mobile: {
    1: '/videos/breath/state-1-mobile.mp4',
    2: '/videos/breath/state-2-mobile.mp4',
  },
  desktop: {
    1: '/videos/breath/state-1-desktop.mp4',
    2: '/videos/breath/state-2-desktop.mp4',
  },
}

const HoldBreath = ({hold, startOnCountDown, onStartTry}) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [state, setState] = useState(1);

  const isMobile = useMediaQuery('only screen and (max-width: 991px)');

  useEffect(() => {
    if (hold) {
      setState(2);
    } else {
      setState(1);
    }

    if (isMobile) {
      setVideoUrl(video.mobile[state]);
    } else {
      setVideoUrl(video.desktop[state]);
    }
  }, [hold, setVideoUrl, state, isMobile]);

  const onCountDownEnd = useCallback(() => {
    onStartTry();
  }, [onStartTry]);

  return (
    <div className="hold-breath-block">
      <ReactPlayer
        playsinline={true}
        className="hold-breath-video"
        playing={true}
        controls={false}
        loop={true}
        muted={true}
        url={videoUrl}
      />
      <Countdown
        count={startOnCountDown}
        callback={onCountDownEnd}
      />
    </div>
  );
}

export default HoldBreath;
