import {useContext, createContext} from 'react';
import {types} from 'mobx-state-tree';
import values from 'lodash/values';
import localStorage from "mobx-localstorage";
import {persist} from 'mst-persist';
import LogRocket from 'logrocket';

import {StepList} from './core/step-list';

import {ReactionStep} from './steps/reaction-step/reaction-step';
import {CnsStep} from './steps/cns-step/cns-step';
import {JumpStep} from './steps/jump-step/jump-step';
import {GenderStep} from './steps/gender-step/gender-step';
import {NameStep} from './steps/name-step/name-step';
import {AgeStep} from './steps/age-step/age-step';
import {ParentsStep} from './steps/parents-step/parents-step';
import {ConstitutionStep} from './steps/constitution-step/constitution-step';
import {PsychotypeStep} from './steps/psychotype-step/psychotype-step';
import {CoordinationStep} from "./steps/coordination-step/coordination-step";
import {BreathStep} from "./steps/breath-step/breath-step";
import {guestStepList} from "./core/stepListData";
import {ORDER_STATUS_APPROVED, ORDER_STATUS_DEPOSITED} from "../orderStatuses";
import {flexibilityList, FlexibilityStep} from "./steps/flexibility-step/flexibility-step";
import TestApiService from "../services/TestApiService";
import ProfileApiService from "../services/ProfileApiService";
import {reaction} from "mobx";

export const ProfileModel = types.model({
  name: types.string,
  phone: types.string,
  email: types.string,
})

export const TestModel = types.model({
  testId: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.number),
  paymentLink: types.maybeNull(types.string),
  completed: types.optional(types.boolean, false),
  payedManually: types.optional(types.boolean, false),

  payed: types.optional(types.boolean, false),
  resultLink: types.optional(types.string, ''),
  resultTimer: types.maybeNull(types.number),
  foxHello: types.optional(types.boolean, false),
  steplist: types.optional(StepList, {}),
  agestep: types.optional(AgeStep, {}),
  reactionstep: types.optional(ReactionStep, {}),
  cnsstep: types.optional(CnsStep, {}),
  jumpstep: types.optional(JumpStep, {}),
  genderstep: types.optional(GenderStep, {}),
  namestep: types.optional(NameStep, {}),
  coordinationStep: types.optional(CoordinationStep, {}),
  breathStep: types.optional(BreathStep, {}),
  parentsstep: types.optional(ParentsStep, {}),
  constitutionstep: types.optional(ConstitutionStep, {}),
  psychotypestep: types.optional(PsychotypeStep, {}),
  flexibilitystep: types.optional(FlexibilityStep, {}),
}).actions((self) => ({
  setFoxHello: (value) => {
    self.foxHello = value;
  }
}));

export const extractTest = (testModel) => {
  const data = {};
  //   +'name' => 'string',
  //   +'game_type' => Rule::in([Test::GAME_TYPE_GUEST, Test::GAME_TYPE_REGULAR]),
  //   =+ 'age' => 'integer|between:6,10',
  //   + 'father_height' => 'integer',
  //   + 'mother_height' => 'integer',
  //   +'sex' => Rule::in([Test::GENDER_MALE, Test::GENDER_FEMALE]),
  //   + 'anthropometry' => Rule::in([Test::ANTHROPOMETRY_ECTO, Test::ANTHROPOMETRY_MESO, Test::ANTHROPOMETRY_ENDO]),
  //   + 'psychology' => Rule::in([Test::PSYCHOLOGY_ART, Test::PSYCHOLOGY_RECORD, Test::PSYCHOLOGY_CONTACT]),
  //   + 'breath_holding_time' => 'integer',
  //   + 'time_to_find_a_solution' => 'integer',
  //   + 'jump_length' => 'integer',
  //   + 'reaction_time' => 'integer',
  //   + 'flexibility' => Rule::in([Test::FLEXIBILITY_BAD, Test::FLEXIBILITY_MIDDLE, Test::FLEXIBILITY_GOOD]),
  //   + 'some_coordination' => Rule::in([Test::COORDINATION_BAD, Test::COORDINATION_MIDDLE, Test::COORDINATION_GOOD])


  // age: "8"
  // anthropometry: "1"
  // flexibility: "2"
  // fox_hello: 1
  // jump_length: "22"
  // name: "Денис1"
  // psychology: "2"
  // reaction_time: 511
  // sex: "2"
  // some_coordination: 735

  if (testModel.agestep.age) {
    data.age = testModel.agestep.age;
  }

  if (testModel.namestep.name) {
    data.name = testModel.namestep.name;
  }

  if (testModel.parentsstep.fatherHeight && testModel.parentsstep.motherHeight) {
    data.father_height = testModel.parentsstep.fatherHeight;
    data.mother_height = testModel.parentsstep.motherHeight;
  }

  if (testModel.genderstep.selectedGender) {
    data.sex = testModel.genderstep.selectedGender.id;
  }

  if (testModel.constitutionstep.selectedConstitution) {
    data.anthropometry = testModel.constitutionstep.selectedConstitution.id;
  }

  if (testModel.psychotypestep.selectedPsychotype) {
    data.psychology = testModel.psychotypestep.selectedPsychotype.id;
  }

  if (testModel.breathStep.trylist.bestResult && testModel.breathStep.trylist.bestResult.duration) {
    data.breath_holding_time = testModel.breathStep.trylist.bestResult.duration;
  }

  if (testModel.cnsstep.trylist.bestResult && testModel.cnsstep.trylist.bestResult.duration) {
    data.time_to_find_a_solution = testModel.cnsstep.trylist.bestResult.duration;
  }

  if (testModel.jumpstep.best) {
    data.jump_length = testModel.jumpstep.best;
  }

  if (testModel.reactionstep.trylist.bestResult) {
    data.reaction_time = testModel.reactionstep.trylist.bestResult;
  }

  if (testModel.flexibilitystep.selectedFlexibility) {
    data.flexibility = testModel.flexibilitystep.selectedFlexibility.id;
  }

  if (testModel.foxHello) {
    data.fox_hello = 1;
  }

  if (testModel.coordinationStep.trylist.bestResult && testModel.coordinationStep.trylist.bestResult.duration) {
    data.some_coordination = testModel.coordinationStep.trylist.bestResult.duration;
  }

  let allStep = false;
  if (values(data).length === 14) {
    allStep = true;
  }

  if (testModel.steplist.strategy) {
    data.game_type = testModel.steplist.strategy;
  }

  return {
    data,
    allStep,
  };
}

export const onNextStep = async (testModel, device) => {
  if (!testModel.testId) {
    return;
  }

  const {data, allStep} = extractTest(testModel);

  const testApiService = new TestApiService();

  if (device && device.cns) {
    data.cns_device = device.cns;
  }

  if (device && device.reaction) {
    data.reaction_device = device.reaction;
  }

  await testApiService.update({
    testId: testModel.testId,
    params: data,
  });

  if (allStep) {
    await testApiService.complete({
      testId: testModel.testId,
    });
  }
}

export const hydrateTest = (testFromApi) => {
  // +id: 13
  // completed_at: null
  // created_at: "2021-04-21T06:31:11.000000Z"
  // download_link: "asdsad"
  // +reaction_time: null
  // download_timer: 0
  // is_sent: 0
  // payment_link: "https://3dsec.sberbank.ru/payment/merchants/sbersafe_sberid/payment_ru.html?mdOrder=e5d9966d-23b0-7d3a-8e5d-32c35e49898a"
  // payment_status: 0

  // +age: 8
  // +jump_length: null
  // anthropometry: null
  // breath_holding_time: null
  // father_height: null
  // flexibility: null

  // mother_height: null
  // name: "Денис"
  // psychology: null
  // sex: null
  // some_coordination: null
  // time_to_find_a_solution: null
  // updated_at: "2021-04-21T06:31:11.000000Z"
  // user_id: 4

  const resultTimer = testFromApi.download_timer || 1800;
  const resultLink = testFromApi.download_link;

  const paymentStatus = testFromApi.payment_status;
  const paymentLink = testFromApi.payment_link;
  const completed = !!testFromApi.completed_at;

  let stepList = {
    currentIndex: 0,
  };

  if (testFromApi.game_type === 'guest') {
    stepList = {
      currentIndex: 0,
      strategy: 'guest',
      items: guestStepList,
    };
  }

  const ageStep = testFromApi.age ? {age: String(testFromApi.age)} : {};
  if (testFromApi.age) {
    stepList.currentIndex += 1;
  }

  const breathStep = testFromApi.breath_holding_time ? {trylist: {bestTime: testFromApi.breath_holding_time}} : {};
  if (testFromApi.breath_holding_time) {
    stepList.currentIndex += 1;
  }

  const reactionStep = testFromApi.reaction_time ? {trylist: {bestTime: testFromApi.reaction_time}} : {};
  if (testFromApi.reaction_time) {
    stepList.currentIndex += 1;
  }

  const cnsStep = testFromApi.time_to_find_a_solution ? {trylist: {bestTime: testFromApi.time_to_find_a_solution}} : {};
  if (testFromApi.time_to_find_a_solution) {
    stepList.currentIndex += 1;
  }

  const jumpStep = testFromApi.jump_length ? {best: String(testFromApi.jump_length)} : {};
  if (testFromApi.jump_length) {
    stepList.currentIndex += 1;
  }

  let genderStep = {};

  if (testFromApi.sex) {
    if (testFromApi.sex === 1) {
      genderStep = {selectedGender: {id: '1', text: 'Мальчик'}};
      stepList.currentIndex += 1;
    } else if (testFromApi.sex === 2) {
      genderStep = {selectedGender: {id: '2', text: 'Девочка'}};
      stepList.currentIndex += 1;
    }
  }

  const nameStep = testFromApi.name ? {name: testFromApi.name} : {};
  if (testFromApi.name) {
    stepList.currentIndex += 1;
  }

  const coordinationStep = testFromApi.some_coordination ? {trylist: {bestTime: testFromApi.some_coordination}} : {};
  if (testFromApi.some_coordination) {
    stepList.currentIndex += 1;
  }

  let parentsStep = {};

  if (testFromApi.father_height && testFromApi.mother_height) {
    parentsStep = {
      fatherHeight: String(testFromApi.father_height),
      motherHeight: String(testFromApi.mother_height),
    }
    stepList.currentIndex += 1;
  }

  let constitutionStep = {};

  if (testFromApi.anthropometry) {
    if (testFromApi.anthropometry === 1) {
      constitutionStep = {selectedConstitution: {id: '1', text: 'Эктоморф'}};
      stepList.currentIndex += 1;
    } else if (testFromApi.anthropometry === 2) {
      constitutionStep = {selectedConstitution: {id: '2', text: 'Мезоморф'}};
      stepList.currentIndex += 1;
    } else if (testFromApi.anthropometry === 3) {
      constitutionStep = {selectedConstitution: {id: '3', text: 'Эндоморф'}};
      stepList.currentIndex += 1;
    }
  }

  let psychotypeStep = {};

  if (testFromApi.psychology) {
    if (testFromApi.psychology === 1) {
      psychotypeStep = {
        selectedPsychotype: {
          id: '1',
          title: 'Художественный',
          description: 'ребенок актер, любит внимание, ждет оценку его работы'
        }
      };
      stepList.currentIndex += 1;
    } else if (testFromApi.psychology === 2) {
      psychotypeStep = {
        selectedPsychotype: {
          id: '2',
          title: 'Рекордный',
          description: 'стремится быть первым, прыгнуть выше, поднять больше, любит соревноваться'
        }
      };
      stepList.currentIndex += 1;
    } else if (testFromApi.psychology === 3) {
      psychotypeStep = {
        selectedPsychotype: {
          id: '3',
          title: 'Контактный',
          description: 'ребенок любит контакт, любит подвижные командные игры, любит бороться, симпатизирует дракам и т.д.'
        }
      };
      stepList.currentIndex += 1;
    }
  }

  let flexibilityStep = {};

  if (testFromApi.flexibility) {
    if (testFromApi.flexibility === 1) {
      flexibilityStep = {
        selectedFlexibility: flexibilityList[2]
      };
      stepList.currentIndex += 1;
    } else if (testFromApi.flexibility === 2) {
      flexibilityStep = {
        selectedFlexibility: flexibilityList[1]
      };
      stepList.currentIndex += 1;
    } else if (testFromApi.flexibility === 3) {
      flexibilityStep = {
        selectedFlexibility: flexibilityList[0]
      };
      stepList.currentIndex += 1;
    }
  }

  let foxHello = false;
  if (testFromApi.fox_hello) {
    foxHello = Boolean(testFromApi.fox_hello);
    stepList.currentIndex += 1;
  }

  const payed = testFromApi.payment_status === ORDER_STATUS_APPROVED || testFromApi.payment_status === ORDER_STATUS_DEPOSITED;

  return TestModel.create({
    resultTimer,
    resultLink,
    paymentStatus,
    paymentLink,
    completed,
    payedManually: Boolean(testFromApi.paid_manually),
    testId: String(testFromApi.id),
    payed,
    foxHello,
    flexibilitystep: flexibilityStep,
    agestep: ageStep,
    reactionstep: reactionStep,
    cnsstep: cnsStep,
    jumpstep: jumpStep,
    genderstep: genderStep,
    namestep: nameStep,
    coordinationStep,
    breathStep,
    parentsstep: parentsStep,
    constitutionstep: constitutionStep,
    psychotypestep: psychotypeStep,
    steplist: stepList,
  });
}

const RootModel = types.model({
  test: types.maybeNull(TestModel),
  profile: types.maybeNull(ProfileModel),
  hydrated: types.optional(types.boolean, false),
}).actions(self => ({
    setHydrated: (value) => {
      self.hydrated = value;
    },
    setTest: (test) => {
      self.test = test;
    },
    setProfile: ({name, email, phone}) => {
      self.profile = ProfileModel.create({name, email, phone});
    },
    clearProfile: () => {
      self.profile = null;
    }
  }
));

export const rootStore = RootModel.create();

const STORE_IN_LOCAL_STORAGE_KEY = 'mst-store-persisted-state';

persist(STORE_IN_LOCAL_STORAGE_KEY, rootStore, {
  jsonify: true,  // if you use AsyncStorage, this shoud be true  default: true
  whitelist: ['profile'],  // only these keys will be persisted
}).finally(() => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const profileApi = new ProfileApiService();

      profileApi.get().then((data) => {
        const {name, email, phone} = data;

        rootStore.setProfile({name, email, phone});
      });
    } catch (error) {
      console.error(error);
      localStorage.setItem('token', '');
      localStorage.setItem('tokenExpiredAt', '');
      rootStore.clearProfile();
    }
  } else {
    rootStore.clearProfile();
  }

  rootStore.setHydrated(true);
});

reaction(() => localStorage.getItem('token'), (value, prevValue) => {
  if (value && !prevValue) {
    try {
      const profileApi = new ProfileApiService();

      profileApi.get().then((data) => {
        const {name, email, phone} = data;

        rootStore.setProfile({name, email, phone});
      });
    } catch (error) {
      console.error(error);
      localStorage.setItem('token', '');
      localStorage.setItem('tokenExpiredAt', '');
      rootStore.clearProfile();
    }
  }
});

if (window.location.host === 'firststep.budsilnym.ru') {
  reaction(() => rootStore.profile, (value, prevValue) => {
    if (value && !prevValue) {
      const {email, name, phone} = value;

      LogRocket.identify(email, {
        name,
        phone,
      });
    }
  });
}

const RootStoreContext = createContext(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }

  return store;
}

export const REGULAR_TEST_TYPE = 'regular';
export const GUEST_TEST_TYPE = 'guest';

export function useTest(testId) {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }

  if (testId === 'new') {
    store.setTest(TestModel.create());
  } else {
    store.setTest(TestModel.create({
      testId,
    }));
  }

  return store.test;
}
