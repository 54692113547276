
// === Еще не платил
// An order was successfully registered, but is'nt paid yet
export const ORDER_STATUS_CREATED = 0;





// === ЖДЕМ бабки
// An order's amount was successfully holded (for two-stage payments only)
export const ORDER_STATUS_APPROVED = 1;
// An order authorization was initialized by card emitter's ACS
export const ORDER_STATUS_AUTHORIZATION_INITIALIZED = 5;


// An order was deposited
// If you want to check whether payment was successfully paid - use this constant
export const ORDER_STATUS_DEPOSITED = 2;



// === Заказ отменен
// An order was reversed
export const ORDER_STATUS_REVERSED = 3;
// An order was refunded
export const ORDER_STATUS_REFUNDED = 4;
// An order was declined
export const ORDER_STATUS_DECLINED = 6;










