import React from 'react';
import Footer from "./Block/Footer";
import Header from "./Block/Header";

const Payment = () => {
  return (
    <div className="landing-root">
      <div className="landingPage-wrapper">
        <div className="landingPage">
          <Header/>
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block">
              <h2>Правила оплаты</h2>
              <p>
                Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку
                Оплата заказа банковской картой. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт
                следующих платёжных систем:<br/>

                <ul>
                  <li>МИР</li>
                  <li>VISA International</li>
                  <li>Mastercard Worldwide</li>
                  <li>JCB</li>
                </ul>

                Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК.
                Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием
                протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения
                интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа
                также может потребоваться ввод специального пароля.<br/><br/>

                Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации
                обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением
                случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в
                строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.<br/><br/>

                <b>Порядок получения услуги</b><br/>
                <br/>
                Наименование услуги: Доступ к цифровому тесту на определение вида спорта для одного ребенка.<br/>
                Стоимость: 300 руб.<br/>

                <ul>
                  <li>Требуется авторизация</li>
                  <li>Логин и пароль будут отправлены вам на почту</li>
                  <li>В личном кабинете или сразу после регистрации пройдите несколько первых этапов теста</li>
                  <li>Перейдите на страницу оплаты</li>
                  <li>Произведите оплату, после оплаты тест будет доступен для продолжения</li>
                  <li>Пройдите остальные шаги теста</li>
                  <li>По окончании теста система сформирует файлы с результатами тестирования</li>
                  <li>Результаты будут направлены вам на email через 30 минут после прохождения теста</li>
                  <li>Так же результат будет доступен в личном кабинете</li>
                  <li>Один тест можно пройти один раз</li>
                  <li>Для повторного тестирования зайдите в ЛК и начините новый тест</li>
                </ul>

                <b>Поддержка</b><br/><br/>

                По вопросам услуги обращаться:<br/>
                +7 (383) 383-09-99<br/>
                +7 (952) 902-18-04<br/>
                konanbarbarian72@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Payment;
