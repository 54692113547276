import React, {useState} from 'react';
import TabContext from './TabContext';

const Tabs = ({children, initialTab = 0}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTab);

  return (
    <TabContext.Provider value={{activeTabIndex: activeTabIndex, setActiveTabIndex}}>
      <div className="tabs">
        {children}
      </div>
    </TabContext.Provider>
  );
}

export default Tabs;
