import {useEffect} from 'react';
import {observer} from 'mobx-react';

import stringifyMs from '../../utils/stringifyMs';

import {
  AppButton,
  PlumberGame,
  Dots,
  StepNav
} from '../core';
import getDevice from "../../utils/getDevice";

function CnsStep({test}) {
  const {cnsstep: {game, trylist}, steplist} = test;

  useEffect(() => {
    game.init();
    game.setCallback(() => {
      trylist.push({moves: game.moves, duration: +(new Date()) - game.timeStarted});
      game.stop();
    });
  }, []);

  const startGame = () => {
    game.start();
  };

  const getStateName = () => {
    if (game.active) {
      return 'playing';
    } else {
      if (trylist.items.length === 0) {
        return 'clean'
      } else if (trylist.items.length === trylist.max) {
        return 'finished';
      } else {
        return 'break';
      }
    }
  }
  const stateName = getStateName();

  const rootClassNameMap = {
    playing: 'step--playing',
    clean: 'step--clean',
    finished: 'step--finished',
    break: 'step--break',
  }
  const rootClassList = ('step step--cns ' + (rootClassNameMap.hasOwnProperty(stateName) ? rootClassNameMap[stateName] : ''));

  const playButtonText = (stateName === 'break') ? 'Еще разок' : 'Начать игру';
  const playButtonDisabled = (stateName === 'playing' || stateName === 'finished');
  const nextStepButtonMode = (stateName === 'finished') ? 'solid' : 'outline';
  const nextStepButtonDisabled = !(stateName === 'finished');

  const goToNextStep = () => {
    if (!nextStepButtonDisabled) {
      const device = getDevice();
      debugger;
      steplist.next({
        cns: device
      });
    }
  }

  return (
    <div className={rootClassList}>
      <div className="panel panel--cns">
        <div className="panel__wrapper">
          <div className="panel__body">
            <div className="panel__title">
              Тест Центральной Нервной Системы
            </div>
            <div className="panel__text">
              Главная и&nbsp;специфическая функция ЦНС&nbsp;&mdash; осуществление простых и&nbsp;сложных рефлексов
            </div>
            <div className="panel__text panel__text--highlighted">
              Помоги бобру наполнить бассейн!<br/>
              Нажми на&nbsp;кусочки труб, чтобы соединить трубопровод.
            </div>
            <div className="panel__button-wrapper">
              <AppButton
                buttonType="solid"
                text={playButtonText}
                onClick={startGame}
                disabled={playButtonDisabled}
              />
            </div>
            <div className="panel__dots">
              <div className="panel__dots-label">
                Попытки
              </div>
              <div className="panel__dots-component">
                <Dots
                  total={trylist.max}
                  done={trylist.items.length}
                  isCurrentActive={game.active}
                />
              </div>
            </div>
            {
              stateName && (
                <div className="panel__text panel__text--highlighted panel__text--finished">
                  Молодец! Ты&nbsp;помог бобру наполнить бассейн!<br/>
                  Теперь можешь продолжить и&nbsp;перейти в&nbsp;следующий шаг.
                </div>
              )
            }
          </div>
          <div className="panel__footer">
            <div className="panel__result">
              <div className="panel__result-label">
                Лучший результат
              </div>
              <div className="panel__result-value">
                {trylist.bestResult && (
                  trylist.bestResult.moves +
                  ' | ' +
                  stringifyMs(trylist.bestResult.duration)
                )}
              </div>
            </div>
            <div className="panel__button-wrapper">
              <AppButton
                buttonType={nextStepButtonMode}
                text="Следующий шаг"
                onClick={goToNextStep}
                disabled={nextStepButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
      <div className="step__playground">
        <div className="step__beaver"/>
        <PlumberGame test={test}/>
      </div>
    </div>
  );
}

export default observer(CnsStep);
