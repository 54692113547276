import React from 'react';
import { observer } from 'mobx-react';

import {TestModel} from '../../models/root';

import {
  StepTarget,
} from '../core';
import {Link} from "react-router-dom";
import CoordinationStep from "../steps/coordination-step";

function CoordinationSingleGamePage() {
  const emptyTest = TestModel.create();

  return (
    <div className="app">
        <CoordinationStep test={emptyTest} />
      <Link className="app__logo" to="/"/>
      <div className="app__step-target">
        <StepTarget value="child" />
      </div>
    </div>
  );
}

export default observer(CoordinationSingleGamePage);
