import {types} from 'mobx-state-tree';

export const CoordinationTryItem = types.model({
  duration: types.optional(types.number, 0)
})

export const BreathTryList = types.model({
  max: types.optional(types.number, 3),
  items: types.optional(types.array(CoordinationTryItem), []),
  bestTime: types.maybeNull(types.number), // Задано только когда состояние теста получено из API
}).actions(self => {

  function push(tryData) {
    self.items = [...self.items, tryData];
  }

  return {
    push
  }
}).views(self => ({
  get bestResult() {
    if (self.bestTime) {
      return {
        duration: self.bestTime,
      }
    }

    let best = {duration: 0};

    if (self.items.length > 0) {
      self.items.forEach((tryItem) => {
        if (tryItem.duration > best.duration) {
          best = tryItem;
        }
      });

      return best;
    } else {
      return null;
    }
  }
}))
