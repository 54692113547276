import React, {useState, useRef, useEffect} from 'react';
import useOutsideClick from "@rooks/use-outside-click";
import {NavHashLink} from 'react-router-hash-link';
import cn from 'classnames';
import {isAuthenticated} from "../../../utils/isAuthenticated";
import {Link} from "react-router-dom";

const Header = () => {
  const [showModal, setShowModal] = useState(false);

  const onLinkClick = () => {
    setShowModal(false);
  }

  const toggleShowModal = () => {
    setShowModal(!showModal);
  }

  const pRef = useRef();

  const hideModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    window.addEventListener('resize', hideModal);

    return () => {
      window.removeEventListener('resize', hideModal);
    }
  }, [])

  useOutsideClick(pRef, hideModal);

  return (
    <div className="header-wrapper">
      <div className="header">
        <div className="left" ref={pRef}>
          <div className="burger" onClick={toggleShowModal}/>
          <div className={cn(['menu', showModal && 'menu--show-modal'])}>
            <NavHashLink
              className="menu__item"
              to="/#test"
              activeClassName="menu__item--active"
              onClick={onLinkClick}
            >
              Про тест
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#trainers"
              onClick={onLinkClick}
            >
              Тренеры
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#sportsmans"
              onClick={onLinkClick}
            >
              Спортсмены
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#test-structure"
              onClick={onLinkClick}
            >
              Структура теста
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#know"
              onClick={onLinkClick}
            >
              Важно знать!
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#test-view"
              onClick={onLinkClick}
            >
              Как выглядит тест
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#results"
              onClick={onLinkClick}
            >
              Результаты
            </NavHashLink>
            <NavHashLink
              className="menu__item"
              activeClassName="menu__item--active"
              to="/#contact"
              onClick={onLinkClick}
            >
              Контакты
            </NavHashLink>
          </div>
        </div>
        <div className="right">
          {!isAuthenticated() && (
            <Link className="enter-lk" to="/login">
              Войти
            </Link>
          )}
          {isAuthenticated() && (
            <Link className="enter-lk" to="/lk">
              Личный кабинет
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header;
