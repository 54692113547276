import { useEffect } from 'react';
import { observer } from 'mobx-react';

import ReactPlayer from 'react-player';

import {
	StepNav
} from '../core';

function PsychotypeStep({test}) {

	const { psychotypestep, steplist } = test;

	useEffect(() => {
		psychotypestep.init();
		psychotypestep.setCallback(() => {
			steplist.next();
		});
	}, []);

	return (
		<div className="plain-step plain-step--psychotype">
			<div className="plain-step__container">
				<div className="plain-step__title">
					Выберите подходящую психологию ребенка
				</div>
				<div className="plain-step__video-wrapper">
					<ReactPlayer className="plain-step__video-player" playing controls={true} url='/videos/info_psychotype.mp4' height="100%" width="100%" />
				</div>
				<div className="plain-step__panel-picker">
					<div className="panel-picker panel-picker--psychotype">
						<div className="panel-picker__list">
							{
								psychotypestep.psychotypeList.map((psychotype, index) => {
									const cssClassList = ('panel-picker__item panel-picker__item--' + psychotype.id);

									return (
										<div className={cssClassList} key={index} onClick={() => psychotypestep.setPsychotype(psychotype.id)}>
											<div className="panel-picker__title">
												{psychotype.title}
											</div>
											<div className="panel-picker__text">
												{psychotype.description}
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
			<div className="plain-step__step-nav">
				<StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex} />
			</div>
		</div>
	);
}

export default observer(PsychotypeStep);
