import React from 'react';
import {observer} from 'mobx-react';
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import IndexLandingPage from "../landing/IndexLandingPage";
import TestPage from "./TestPage";
import Payment from "../landing/Payment";
import Policy from "../landing/Policy";
import ScrollToTop from "../core/ScrollToTop";
import LoginPage from "../auth/LoginPage";
import LKPage from "../auth/LKPage";
import CoordinationSingleGamePage from "../singleGame/CoordinationSingleGamePage";
import BreathSingleGamePage from "../singleGame/BreathSingleGamePage";

import {ABOUT, INDEX, LK, LOGIN, PAYMENT_RULES, POLICY, SUCCESS, TEST} from "../../routes";
import CnsSingleGamePage from "../singleGame/CnsSingleGamePage";
import ReactionSingleGamePage from "../singleGame/ReactionSingleGamePage";
import DevelopGame from "../singleGame/DevelopGame";
import PaymentSuccess from "../steps/payment-success";
import About from "../landing/About";


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route path={INDEX} exact={true}>
          <IndexLandingPage/>
        </Route>

        <Route path={`${TEST}/:testId`} exact={true}>
          <TestPage/>
        </Route>

        <Route path={`${SUCCESS}/:testId`} exact={true}>
          <PaymentSuccess/>
        </Route>

        <Route path={PAYMENT_RULES} exact={true}>
          <Payment/>
        </Route>

        <Route path={POLICY} exact={true}>
          <Policy/>
        </Route>

        <Route path={ABOUT} exact={true}>
          <About/>
        </Route>

        <Route path={LOGIN} exact={true}>
          <LoginPage/>
        </Route>

        <Route path={LK} exact={true}>
          <LKPage/>
        </Route>

        <Route path="/game/coordination" exact={true}>
          <CoordinationSingleGamePage/>
        </Route>
        <Route path="/game/breath" exact={true}>
          <BreathSingleGamePage/>
        </Route>
        <Route path="/game/reaction" exact={true}>
          <ReactionSingleGamePage/>
        </Route>
        <Route path="/game/cns" exact={true}>
          <CnsSingleGamePage/>
        </Route>
        <Route path="/dev" exact={true}>
          <DevelopGame/>
        </Route>
        <Redirect to={INDEX}/>
      </Switch>
    </Router>
  )
}

export default observer(App);
