import { types } from 'mobx-state-tree';
import { PlumberTube } from './plumber-tube';
import getRandomFromArray from '../../../utils/getRandomFromArray';
import tryNTimes from '../../../utils/tryNTimes';

export const PlumberGame = types.model({
	active: false,
	moves: types.optional(types.number, 0),
	tubes: types.optional(types.array(types.optional(types.array(types.maybeNull(PlumberTube), null), [])), []),
	maxRows: 3,
	maxCols: 6,
	durationShown: types.optional(types.number, 0),
	timeStarted: types.optional(types.number, 0)
}).actions(self => {

	let localTimeout;

	function cleanTubeData(tubeData) {
		return tubeData.map((tubeRow) => tubeRow.map((tube) => {
			return tube ? PlumberTube.create({
				...tube,
				active: false
			}) : null;
		}));
	}

	function checkActiveTube(tubeData, rowIndex, colIndex) {
		const tubeLink = tubeData[rowIndex][colIndex];

		if(rowIndex === 0 && colIndex === 0) {
			return tubeLink.sideData[0] === 1;
		} else {
			return getConnectedTubes(tubeData, rowIndex, colIndex)
				.map((indexes) => tubeData[indexes[0]][indexes[1]])
				.some((tube) => tube.active);
		}
	}

	function checkActiveTubeCycle(tubeData, rowIndex, colIndex, callback) {
		const tubeLink = tubeData[rowIndex][colIndex];

		if(checkActiveTube(tubeData, rowIndex, colIndex)) {
			tubeLink.setActive(true);

			const emptyTargets = getConnectedTubes(tubeData, rowIndex, colIndex)
				.filter((indexes) => (tubeData[indexes[0]][indexes[1]] && !tubeData[indexes[0]][indexes[1]].active));

			if(emptyTargets[0]) {
				checkActiveTubeCycle(tubeData, emptyTargets[0][0], emptyTargets[0][1], callback);
			} else {
				callback(tubeData);
			}
		} else {
			tubeLink.setActive(false);
			callback(tubeData);
		}
	}

	function fullCheckActiveTube(tubeData, callback) {

		const cleanData = cleanTubeData(tubeData);

		checkActiveTubeCycle(cleanData, 0, 0, (newTubeData) => {
			callback(newTubeData);
		});
	}

	function getActiveCount(tubeData) {
		let count = 0;

		tubeData.forEach((tubeRow) => {
			tubeRow.forEach((tube) => {
				if(tube && tube.active) {
					count++;
				}
			})
		});

		return count;
	}

	function getConnectableTubes(tubeData, rowIndex, colIndex) {
		const tubeLink = tubeData[rowIndex][colIndex];

		let tubeList = [];

		if(tubeLink.sideData[0] === 1 && rowIndex !== 0) {
			tubeList.push([rowIndex - 1, colIndex])
		}
		if(tubeLink.sideData[1] === 1 && colIndex < (self.maxCols - 1)) {
			tubeList.push([rowIndex, colIndex + 1])
		}
		if(tubeLink.sideData[2] === 1 && rowIndex < (self.maxRows - 1)) {
			tubeList.push([rowIndex + 1, colIndex])
		}
		if(tubeLink.sideData[3] === 1 && colIndex !== 0) {
			tubeList.push([rowIndex, colIndex - 1])
		}

		return tubeList;
	}

	function getConnectedTubes(tubeData, rowIndex, colIndex) {
		const tubeLink = tubeData[rowIndex][colIndex];

		let tubeList = [];

		if(
			tubeLink.sideData[0] === 1 && rowIndex !== 0 && 
			tubeData[rowIndex - 1][colIndex] && tubeData[rowIndex - 1][colIndex].sideData[2] === 1
		) {
			tubeList.push([rowIndex - 1, colIndex])
		}
		if(
			tubeLink.sideData[1] === 1 && colIndex < (self.maxCols - 1) &&
			tubeData[rowIndex][colIndex + 1] && tubeData[rowIndex][colIndex + 1].sideData[3] === 1
		) {
			tubeList.push([rowIndex, colIndex + 1])
		}
		if(
			tubeLink.sideData[2] === 1 && rowIndex < (self.maxRows - 1) &&
			tubeData[rowIndex + 1][colIndex] && tubeData[rowIndex + 1][colIndex].sideData[0] === 1
		) {
			tubeList.push([rowIndex + 1, colIndex])
		}
		if(
			tubeLink.sideData[3] === 1 && colIndex !== 0 &&
			tubeData[rowIndex][colIndex - 1] && tubeData[rowIndex][colIndex - 1].sideData[1] === 1
		) {
			tubeList.push([rowIndex, colIndex - 1])
		}

		return tubeList;
	}

	function generateRandomTube() {
		return {
			active: false,
			type: getRandomFromArray(['straight', 'bend']),
			shiftLevel: getRandomFromArray([0,1,2,3]),
		}	
	}

	function generateEmptyData() {
		let newTubeData = [];
		while(newTubeData.length < self.maxRows) {
			let tubeRow = [];
			while(tubeRow.length < self.maxCols) {
				tubeRow.push(null);
			}
			newTubeData.push(tubeRow);
		}

		return newTubeData;
	}

	function generateRandomData() {
		let newTubeData = [];
		while(newTubeData.length < self.maxRows) {
			let tubeRow = [];
			while(tubeRow.length < self.maxCols) {
				tubeRow.push(PlumberTube.create(generateRandomTube()));
			}
			newTubeData.push(tubeRow);
		}

		return newTubeData;
	}

	function tryToGeneratePath(finalCallback) {
		let newTubeData = generateEmptyData();

		let pathLength = 1;

		newTubeData[0][0] = PlumberTube.create(generateRandomTube());
		fullCheckActiveTube(newTubeData, (newerTubeData) => {
			newTubeData = newerTubeData;
		});

		const connectableTubeSpots = (tubeData, rowIndex, colIndex) => {
			return getConnectableTubes(tubeData, rowIndex, colIndex).filter((indexes) => {
				return !(tubeData[indexes[0]][indexes[1]])
			});
		}

		const rotateUntilDone = (indexes, success, fail) => {
			let activeCount = getActiveCount(newTubeData);
			let connectableTubeSpotsCount = connectableTubeSpots(newTubeData, indexes[0], indexes[1]);

			const condition = () => {

				return (
					newTubeData[indexes[0]][indexes[1]].active &&
					(
						connectableTubeSpots(newTubeData, indexes[0], indexes[1]).length > 0 ||
						(indexes[0] === self.maxRows-1 && indexes[1] === self.maxCols-1 && newTubeData[indexes[0]][indexes[1]].sideData[2] === 1)
					)
				);
			}
			const innerRotateFn = () => {
				newTubeData[indexes[0]][indexes[1]].rotate();
				fullCheckActiveTube(newTubeData, (newerTubeData) => {
					newTubeData = newerTubeData;
				});
			}

			if(!condition()) {
				tryNTimes(4, condition, innerRotateFn, () => {
					if(success) success();
				}, () => {
					if(fail) fail();
				})

			} else {
				if(success) success();
			}
		}

		const spawnAfter = (indexes, callback) => {
			const possibleConnections = connectableTubeSpots(newTubeData, indexes[0], indexes[1]);

			fullCheckActiveTube(newTubeData, (newerTubeData) => {
				newTubeData = newerTubeData;
			});

			if(possibleConnections.length > 0) {
				newTubeData[possibleConnections[0][0]][possibleConnections[0][1]] = PlumberTube.create(generateRandomTube());
				if(callback) callback(possibleConnections[0]);
			} else {
				callback(false);
			}

		}

		let firstTarget = [0, 0];

		if(newTubeData[0][0].sideData[0] == 1 && getConnectableTubes(newTubeData, 0, 0).length) {
			firstTarget = getConnectableTubes(newTubeData, 0, 0)[0];
			
			spawnAfter([0, 0]);
		}


		const spawnAnRotateCycle = (indexes, callback) => {
			spawnAfter(indexes, (newIndexes) => {
				if(newIndexes) {
					rotateUntilDone(newIndexes, () => {
						spawnAnRotateCycle(newIndexes, callback);
					}, callback)
				} else {
					callback();
				}
			}, () => {
				callback();
			})
		}

		rotateUntilDone(firstTarget, () => {
			spawnAnRotateCycle(firstTarget, () => {
				finalCallback(newTubeData);
			});
		}, () => {
			// console.log('first success');
		});
	}

	function generatePathCycle(callback) {
		tryToGeneratePath((newTubeData) => {

			const lastTube = newTubeData[self.maxRows-1][self.maxCols-1];

			const restart = !lastTube || lastTube.sideData[2] !== 1 || !lastTube.active;

			if(!restart) {
				callback(newTubeData);
			} else {
				generatePathCycle(callback);
			}
		})
	}

	function seed(callback) {
		generatePathCycle((newTubeData) => {
			newTubeData = newTubeData
				.map((tubeRow) => {
					return tubeRow.map((tube) => {
						return (tube ? (
							PlumberTube.create({
								...tube,
								shiftLevel: getRandomFromArray([0,1,2,3].filter((level) => level !== tube.shiftLevel))
							})
						) : (
							PlumberTube.create(generateRandomTube())
						))
					})
				});
			self.tubes = cleanTubeData(newTubeData);
			fullCheckActiveTube(self.tubes, (newTubeData) => {
				self.tubes = newTubeData;
				if(callback) callback();
			});
		})
	}

	function setCallback(callback) {
		self.callback = callback;
	}

	function checkForSuccess() {
		const lastTube = self.tubes[self.maxRows-1][self.maxCols-1];

		if(lastTube && lastTube.sideData[2] === 1 && lastTube.active) {
			if(self.callback) self.callback();
		}
	}

	function rotateTube(rowIndex, colIndex) {
		if(!self.active) return;
		self.tubes[rowIndex][colIndex].rotate();
		self.moves += 1;

		fullCheckActiveTube(self.tubes, (newTubeData) => {
			self.tubes = newTubeData;
			checkForSuccess();
		});
	}

	function init() {
		self.moves = 0;
		self.tubes = generateEmptyData();
	}

	function setTime() {
		self.timeStarted = +(new Date());
	}

	function updateDurationShown() {
		self.durationShown = +(new Date()) - self.timeStarted;
	}

	function timerCycle() {
		self.updateDurationShown();
		localTimeout = setTimeout(() => {
			if(self.active) timerCycle()
		})
	}

	function start() {
		self.active = true;
		self.moves = 0;
		setTime();
		timerCycle();
		seed(() => {
			setTime();
			timerCycle();
		});
	}

	function stop() {
		self.active = false;
		clearInterval(localTimeout);
	}

	return {
		updateDurationShown,
		setCallback,
		init,
		rotateTube,
		start,
		stop
	}
})