export const DEVICE_DESKTOP = 'DESKTOP';
export const DEVICE_MOBILE = 'MOBILE';

const getDevice = () => {
	const match = window.matchMedia || window.msMatchMedia;
	if(match) {
		const mq = match("(pointer:coarse)");

		if(mq.matches) {
			return DEVICE_MOBILE;
		}
	}

	return DEVICE_DESKTOP;
}

export default getDevice;
