import {observer} from 'mobx-react';
import ReactPlayer from 'react-player';

import {
  AppButton,
  AppInput,
  StepNav
} from '../core';
import ArrowRight from "../../assets/images/arrow-right.svg";

function JumpStep({test}) {
  const {jumpstep, steplist} = test;

  const rootClassList = ('step step--jump');

  const nextStepButtonMode = (jumpstep.bestValid) ? 'solid' : 'outline';
  const nextStepButtonDisabled = !jumpstep.bestValid;

  const goToNextStep = () => {
    if (!nextStepButtonDisabled) {
      steplist.next();
    }
  }

  return (
    <div className={rootClassList}>
      <div className="jump-content">
        <div className="jump-content__title">
          Длина прыжка
        </div>
        <div className="jump-content__text">
          Давай проверим как далеко ты&nbsp;прыгаешь!<br/><br/>

          1. Встань ровно, ноги вместе<br/>
          2. Сделай замах руками<br/>
          3. Руки и&nbsp;таз отведи назад<br/>
          4. Прыгай, оттолкнувшись двумя ногами и&nbsp;руки уводи вперед<br/>
          5. Измерь расстояние после приземления до&nbsp;пяток
        </div>
        <div className="jump-content__video">
          <ReactPlayer
            className="jump-content__player"
            playing
            controls={true}
            url='/videos/tiger_jump.mp4'
            height="100%"
            width="100%"
          />
        </div>
        <div className="jump-content__text">
          Сделай три попытки и&nbsp;запиши лучший результат
        </div>
        <div className="jump-content__input-wrapper input-group--white">
          <AppInput
            label="Длина, см"
            value={jumpstep.best}
            inputType="number"
            onChange={(newValue) => jumpstep.setBest(newValue)}
          />
        </div>
        <div className="jump-content__button-wrapper">
          <AppButton
            buttonType={nextStepButtonMode}
            text={<>Следующий шаг <img src={ArrowRight} alt=""/></>}
            onClick={goToNextStep}
            disabled={nextStepButtonDisabled}
          />
        </div>
      </div>
      <div className="step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
    </div>
  );
}

export default observer(JumpStep);
