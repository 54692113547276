import React from 'react';
import {useHistory, useParams} from "react-router-dom";
import {observer} from 'mobx-react';
import {AppButton} from "../core";
import {TEST} from "../../routes";

import ArrowRight from './../../assets/images/arrow-right.svg';

const PaymentSuccess = ({test}) => {
  const  {testId} = useParams();
  const history = useHistory();

  const onTestClick = () => {
    history.replace(`${TEST}/${testId}`);
  }

  return (
    <div className="app app--system">
      <div className="payment-success-content">

        <h2>Спасибо!</h2>

        <p>Тест успешно создан, теперь можете продолжить.</p>

        <p>Результаты теста будут готовы сразу после прохождения всех шагов и&nbsp;всегда доступны в&nbsp;личном
          кабинете.</p>

        <p className="yellow">
          Вас и&nbsp;вашего ребенка ждёт увлекательное путешествие в&nbsp;мир спортивных достижений и
          наград!
        </p>

        <div className="start-game-wrapper">
          <AppButton
            buttonType="solid"
            text={<>Продолжить <img src={ArrowRight} alt=""/></>}
            onClick={onTestClick}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(PaymentSuccess);
