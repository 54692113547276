import { types } from 'mobx-state-tree';

const Psychotype = types.model({
	id: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	description: types.optional(types.string, ''),
})

export const PsychotypeStep = types.model({
	selectedPsychotype: types.optional(types.maybeNull(Psychotype), null),
	psychotypeList: types.optional(types.array(Psychotype), [])
}).actions(self => {

	function loadPsychotypeList(callback) {
		callback([
			{id: '1', title: 'Художественный', description: 'ребенок актер, любит внимание, ждет оценку его работы'},
			{id: '2', title: 'Рекордный', description: 'стремится быть первым, прыгнуть выше, поднять больше, любит соревноваться'},
			{id: '3', title: 'Контактный', description: 'ребенок любит контакт, любит подвижные командные игры, любит бороться, симпатизирует дракам и т.д.'},
		])
	}

	function init() {
		loadPsychotypeList((data) => {
			self.psychotypeList = data;
		})
	}

	function setPsychotype(PsychotypeId) {
		const foundItem = self.psychotypeList.filter((item) => item.id === PsychotypeId)[0];
		self.selectedPsychotype = Psychotype.create({...foundItem});

		if(self.callback) self.callback(self.selectedPsychotype);
	}

	function setCallback(callback) {
		self.callback = callback;
	}

	return {
		init,
		setPsychotype,
		setCallback
	}
});
