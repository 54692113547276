import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import localStorage from 'mobx-localstorage';
import {observer} from 'mobx-react';

import {isAuthenticated} from "../../utils/isAuthenticated";
import AuthApiService from "../../services/AuthApiService";
import {AppButton} from "../core";
import LkHeader from "../core/LkHeader";


const LoginPage = () => {
  const history = useHistory();
  const loggedIn = isAuthenticated();

  const [pending, setPending] = useState(false);

  const authApiService = new AuthApiService();

  useEffect(() => {
    if (loggedIn) {
      history.push('/lk');
    }
  }, [history, loggedIn]);

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  }
  const onLoginChange = (event) => {
    setLogin(event.target.value);
  }

  const onLoginFormSubmit = async () => {
    if (pending) {
      return false;
    }

    setPending(true);

    if (!login.trim()) {
      alert('Укажите e-mail');
      setPending(false);

      return;
    }

    if (!password.trim()) {
      alert('Укажите пароль');
      setPending(false);

      return;
    }

    let result = false;

    try {
      result = await authApiService.login({email: login, password});
    } catch (e) {
      console.error(e);
    }

    if (result === false) {
      setPending(false);
      alert('Не правильный логин или пароль');

      return false;
    }

    const accessToken = result.access_token;

    localStorage.setItem('token', accessToken);
    localStorage.setItem('tokenExpiredAt', String(Math.ceil(new Date().getTime()) + 60 * 60 * 24 * 30)); // 1 месяц

    setPending(false);
    return false;
  }

  return (
    <div className="app app--system">
      <LkHeader/>
      <div className="login-form">
        <div className="plain-step__title">Вход в личный кабинет</div>
        <div>
          <div className="input-group input-group--white">
            <label htmlFor="login-email">E-mail</label>
            <input type="text" id="login-email" name="email" value={login} onChange={onLoginChange}/>
          </div>

          <div className="input-group input-group--white">
            <label htmlFor="login-password">Пароль</label>
            <input type="password" id="login-password" name="password" value={password}
                   onChange={onPasswordChange}/>
          </div>

          <br/>
          <AppButton
            disabled={pending}
            pending={pending}
            buttonType="solid"
            text="Войти"
            onClick={onLoginFormSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(LoginPage);
