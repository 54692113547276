import cn from 'classnames';

const StepNav = ({stepActive, stepsLength}) => (
  <div className="step-nav">
    <div className="step-nav__list">
      {
        [...Array(stepsLength)].map((x, i) => {
          return (
            <div

              className={cn([
                'step-nav__item',
                stepActive > i && 'step-nav__item--passed',
                stepActive === i && 'step-nav__item--active',
              ])}
              key={i}
            />
          );
        })
      }
    </div>
  </div>
);

export default StepNav;
