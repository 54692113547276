import { types } from 'mobx-state-tree';

const tubeTypeMap = {
	'straight': [0, 1, 0, 1],
	'bend': [0, 0, 1, 1],
}

export const PlumberTube = types.model({
	active: false,
	shiftLevel: types.optional(types.number, 0),
	type: types.union(types.literal('bend'), types.literal('straight')),
}).actions(self => {

	const maxShiftLevel = 3;

	function rotate() {
		if(self.shiftLevel < maxShiftLevel) {
			self.shiftLevel += 1;
		} else {
			self.shiftLevel = 0;
		}
	}

	function setActive(value) {
		self.active = value;
	}

	return {
		rotate,
		setActive
	}
}).views(self => ({
	get sideData() {
		let cleanSideData = tubeTypeMap[self.type];

		if(self.shiftLevel === 0) {
			return cleanSideData;
		} else {
			let secondSlice = cleanSideData.slice(0, 4 - self.shiftLevel);
			let firstSlice = cleanSideData.slice(-self.shiftLevel);

			return firstSlice.concat(secondSlice);
		}
	}
}))