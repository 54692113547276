export const INDEX = '/';
export const LOGIN = '/login';
export const TEST = '/test';
export const SUCCESS = '/success';
export const POLICY = '/policy';
export const PAYMENT_RULES = '/payment';
export const ABOUT = '/about';
export const LK = '/lk';
export const NEW_TEST = '/test/new';

