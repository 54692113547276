import React, {useContext, useCallback} from 'react';

import cn from 'classnames';
import TabContext from "./TabContext";

const Tab = ({children, index}) => {
  const {activeTabIndex, setActiveTabIndex} = useContext(TabContext);

  const onClick = useCallback(() => {
    setActiveTabIndex(index);
  }, [setActiveTabIndex, index]);

  return (
    <div
      className={cn(
        ['tab',
          index === activeTabIndex && 'tab--active'
        ])}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default Tab;
