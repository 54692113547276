import {observer} from 'mobx-react';

import React, {useState} from 'react';
import {
  AppButton,
  StepNav
} from '../core';
import InputMask from "react-input-mask";
import FeedBackApiService from "../../services/FeedBackApiService";
import {Link} from "react-router-dom";
import {POLICY} from "../../routes";

const ages = [6, 7, 8, 9, 10];

function AgeStep({ test }) {
  const {agestep, steplist} = test;

  const feedBackApiService = new FeedBackApiService();
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [pending, setPending] = useState(false);

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const onFormSubmit = (event) => {
    event.preventDefault();
    return false;
  }

  const onFeedBackSubmit = (event) => {
    if (pending) {
      return false;
    }
    setPending(true);

    if (!name.trim()) {
      alert('Укажите имя');
      setPending(false);

      return;
    }

    const clearPhone = phone.match(/\d/g).join("");
    if (clearPhone.length !== 11) {
      alert('Укажите номер телефона');
      setPending(false);

      return;
    }

    setPhone('');
    setName('');

    feedBackApiService.sendFeedback({name, phone});
    setPending(false);
    alert('С вами свяжется наш менеджер');

    return false;
  }

  return (
    <div className="plain-step">
      <div className="plain-step__container">
        <div className="plain-step__title">
          Сколько полных лет ребенку?
        </div>
        <div className="plain-step__field">
          <div className="field">
            <div className="age-buttons">
              {
                ages.map((age) => {
                  return (
                    <div className="plain-step__button" key={age}>
                      <AppButton
                        buttonType="solid"
                        text={`${age} лет`}
                        onClick={() => {
                          agestep.setAge(String(age));
                          steplist.next();
                        }}
                      />
                    </div>
                  )
                })
              }
            </div>
            <div className="no-age">
              <h2>Нет подходящего возраста</h2>
              <p>
                Данный тест рассчитан на&nbsp;детей в&nbsp;возрасте от&nbsp;6&nbsp;до&nbsp;10&nbsp;лет.<br/>
                Если ваш ребенок другого возраста, свяжитесь с&nbsp;нами,
                мы&nbsp;проконсультируем и&nbsp;подскажем с&nbsp;чего начать.
              </p>

              <form className="feedback" onSubmit={onFormSubmit}>
                <div className="input-group input-group--white">
                  <label htmlFor="feedback-name">Ваше имя</label>
                  <input type="text" id="feedback-name" name="name" value={name} onChange={onNameChange}/>
                </div>
                <InputMask mask="+7 (999) 999 99 99" value={phone} onChange={onChangePhone}>
                  {(inputProps) => (
                    <div className="input-group input-group--white">
                      <label htmlFor="feedback-phone">Телефон</label>
                      <input type="tel"{...inputProps} id="feedback-phone" name="phone"/>
                    </div>
                  )}
                </InputMask>
                <AppButton
                  buttonType="outline"
                  text="Перезвоните мне"
                  onClick={onFeedBackSubmit}
                />
                <i className="policy">
                  Нажимая на&nbsp;кнопку &laquo;Перезвоните мне&raquo;, я&nbsp;даю согласие<br/>
                  на&nbsp;обработку <Link to={POLICY}>персональных данных</Link>
                </i>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="plain-step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
    </div>
  );
}

export default observer(AgeStep);
