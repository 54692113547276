import React, {useState} from 'react';
// import {Link} from "react-router-dom";
import {observer} from 'mobx-react';
import {AppButton} from "../core";
// import {PAYMENT_RULES, POLICY} from "../../routes";
import TestApiService from "../../services/TestApiService";
import {isAuthenticated} from "../../utils/isAuthenticated";
import {useMst} from "../../models/root";

const PaymentStep = ({test}) => {
  const {namestep, agestep, breathStep, steplist} = test;

  const testApiService = new TestApiService();
  const loggedIn = isAuthenticated();

  const store = useMst();

  const [pending, setPending] = useState(false);

  const onTestBuyClick = async () => {
    setPending(true);

    let result;
    if (steplist.strategy === 'guest') {
      result = await testApiService.buyAsGuest({
        name: namestep.name,
        age: agestep.age,
        breathHoldingTime: breathStep.trylist.bestResult.duration,
      });
    } else {
      result = await testApiService.buy({
        name: namestep.name,
        age: agestep.age,
      });
    }

    if (result.paymentUrl) {
      window.location.replace(result.paymentUrl)
    } else {
      setPending(false);
    }
  }

  return (
    <div className="app app--system">
      <div className="payment-page-content">
        {loggedIn && store.profile && (
          <div className="you-login-as">
            Вы вошли как:<br/>
            {store.profile.name}
          </div>
        )}
        <div className="plain-step__title">Новый тест</div>

        <p className="line line--first line--icon line--padding">
          Тест на определение вида спорта для одного ребенка
        </p>
        <p className="line line--padding">
          Создан по авторской методике.
        </p>
        <p className="line line--mb-10">
          Получение результатов:
        </p>
        <p className="line line--icon line--padding line--mb-10">
          В личном кабинете
        </p>
        <hr/>
        <div className="price-block">
          <div className="price-block__title">
            Стоимость
          </div>
          <div className="price-block__value">
            Бесплатно
          </div>
        </div>
        <div className="buy-new-game-wrapper">
          <AppButton
            disabled={pending}
            pending={pending}
            buttonType="solid"
            text="Продолжить"
            onClick={onTestBuyClick}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(PaymentStep);
