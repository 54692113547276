import {v4} from 'uuid';

const AppInput = (
  {
    value,
    id,
    disabled = false,
    inputType = 'text',
    label = '',
    min = '0',
    step = '1',
    onChange
  }
) => {

  let _id = id;
  if (!_id) {
    _id = v4();
  }

  const classList = (
    'input ' +
    (value ? 'input--filled' : 'input--empty')
  );

  const onChangeWrapper = (newValue) => {
    if (!disabled) {
      onChange(newValue);
    }
  }

  const restProps = inputType === 'number' ? {
    step,
    min
  } : {};

  return (
    <>
      <div className="input-group app--input">
        <label htmlFor={_id}>{label}</label>
        <input
          value={value}
          type={inputType}
          id={_id}
          disabled={disabled}
          onChange={(event) => onChangeWrapper(event.target.value)}
          {...restProps}
        />
      </div>
      {/*<input*/}
      {/*  className={classList}*/}
      {/*  id={id + '0s'}*/}
      {/*  type={inputType}*/}
      {/*  onChange={(event) => onChangeWrapper(event.target.value)}*/}
      {/*  disabled={disabled}*/}
      {/*  {...restProps}*/}
      {/*/>*/}
    </>
  );
  //
  // return (
  //   <input
  //     className={classList}
  //     id={id}
  //     type={inputType}
  //     onChange={(event) => onChangeWrapper(event.target.value)}
  //     disabled={disabled}
  //     {...restProps}
  //   />
  // );
};

export default AppInput;
