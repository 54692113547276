import { types } from 'mobx-state-tree';

const Constitution = types.model({
	id: types.optional(types.string, ''),
	text: types.optional(types.string, ''),
})

export const ConstitutionStep = types.model({
	selectedConstitution: types.optional(types.maybeNull(Constitution), null),
	constitutionList: types.optional(types.array(Constitution), [])
}).actions(self => {

	function loadConstitutionList(callback) {
		callback([
			{id: '1', text: 'Эктоморф'},
			{id: '2', text: 'Мезоморф'},
			{id: '3', text: 'Эндоморф'},
		])
	}

	function init() {
		loadConstitutionList((data) => {
			self.constitutionList = data;
		})
	}

	function setConstitution(constitutionId) {
		const foundItem = self.constitutionList.filter((item) => item.id === constitutionId)[0];
		self.selectedConstitution = Constitution.create({...foundItem});

		if(self.callback) self.callback(self.selectedGender);
	}

	function setCallback(callback) {
		self.callback = callback;
	}

	return {
		init,
		setConstitution,
		setCallback
	}
}).views(self => ({
	get bestValid() {

		return !!self.best;
	}
}))