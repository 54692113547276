import {useEffect} from 'react';
import {observer} from 'mobx-react';

import {
  StepNav
} from '../core';
import ReactPlayer from "react-player";

function ConstitutionStep({test}) {

  const {constitutionstep, steplist} = test;

  useEffect(() => {
    constitutionstep.init();
    constitutionstep.setCallback(() => {
      steplist.next();
    });
  }, []);

  return (
    <div className="plain-step plain-step--constitution">
      <div className="plain-step__container">
        <div className="plain-step__title">
          Какой тип телосложения?
        </div>
        <div className="plain-step__video-wrapper">
          <ReactPlayer className="plain-step__video-player" playing controls={true} url='/videos/3-constitution.mp4' height="100%" width="100%" />
        </div>
        <div className="plain-step__subtitle">
          Выберите, к какому типу телосложения относится ребенок
        </div>
        <div className="plain-step__card-picker">
          <div className="card-picker card-picker--constitution">
            <div className="card-picker__list">
              {
                constitutionstep.constitutionList.map((constitution, index) => {
                  const cssClassList = ('card-picker__item card-picker__item--' + constitution.id)
                  return (
                    <div className={cssClassList} key={index}
                         onClick={() => constitutionstep.setConstitution(constitution.id)}>
                      <div className="card-picker__image"/>
                      <div className="card-picker__title">
                        {constitution.text}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="plain-step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
    </div>
  );
}

export default observer(ConstitutionStep);
