import {useEffect} from 'react';
import {observer} from 'mobx-react';

import {
  AppButton,
  HareholeGame,
  Dots,
  StepNav
} from '../core';
import getDevice from "../../utils/getDevice";

function ReactionStep({test}) {
  const {reactionstep: {game, trylist}, steplist} = test;

  useEffect(() => {
    game.init();
    game.harelist.setCallback(() => {
      game.stop();
      trylist.push(game.harelist);
      game.init();
    });
  }, []);

  const startGame = () => {
    game.start();
  };

  const getStateName = () => {
    if (game.active) {
      return 'playing';
    } else {
      if (trylist.items.length === 0) {
        return 'clean'
      } else if (trylist.items.length === trylist.max) {
        return 'finished';
      } else {
        return 'break';
      }
    }
  }
  const stateName = getStateName();

  const rootClassNameMap = {
    playing: 'step--playing',
    clean: 'step--clean',
    finished: 'step--finished',
    break: 'step--break',
  }
  const rootClassList = ('step step--reaction ' + (rootClassNameMap.hasOwnProperty(stateName) ? rootClassNameMap[stateName] : ''));

  const playButtonText = (stateName === 'break') ? 'Еще разок' : 'Начать игру';
  const playButtonDisabled = (stateName === 'playing' || stateName === 'finished');
  const nextStepButtonMode = (stateName === 'finished') ? 'solid' : 'outline';
  const nextStepButtonDisabled = !(stateName === 'finished');

  const goToNextStep = () => {
    if (!nextStepButtonDisabled) {
      const device = getDevice();
      steplist.next({
        reaction: device
      });
    }
  }

  return (
    <div className={rootClassList}>
      <div className="panel panel--reaction">
        <div className="panel__wrapper">
          <div className="panel__body">
            <div className="panel__title">
              Упражнение на скорость реакции
            </div>
            <div className="panel__text">
              Скорость реакции - это время от начала подачи сигнала до ответной реакции организма
            </div>
            <div className="panel__text panel__text--highlighted">
              Помоги волку поймать зайцев!<br/>
              Нажми на них, как только увидишь!
            </div>
            <div className="panel__button-wrapper">
              <AppButton
                buttonType="solid"
                text={playButtonText}
                onClick={startGame}
                disabled={playButtonDisabled}
              />
            </div>
            <div className="panel__dots">
              <div className="panel__dots-label">
                Попытки
              </div>
              <div className="panel__dots-component">
                <Dots
                  total={trylist.max}
                  done={trylist.items.length}
                  isCurrentActive={game.active}
                />
              </div>
            </div>
            {
              stateName && (
                <div className="panel__text panel__text--highlighted panel__text--finished">
                  Молодец! Ты поймал всех зайцев!<br/>
                  Теперь можешь продолжить и перейти в следующий шаг.
                </div>
              )
            }
          </div>
          <div className="panel__footer">
            <div className="panel__result">
              <div className="panel__result-label">
                Лучший результат
              </div>
              <div className="panel__result-value">
                {trylist.bestResult ? trylist.bestResult + ' мс' : ''}
              </div>
            </div>
            <div className="panel__button-wrapper">
              <AppButton
                buttonType={nextStepButtonMode}
                text="Следующий шаг"
                onClick={goToNextStep}
                disabled={nextStepButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
      <div className="step__wolf"/>
      <div className="step__playground">
        <HareholeGame test={test}/>
      </div>
    </div>
  );
}

export default observer(ReactionStep);
