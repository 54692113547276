import {Transition} from 'react-transition-group';
import React from 'react';

const duration = 700;

const FadeNumber = ({in: inProp, children}) => (
  <Transition in={inProp} timeout={duration}>
    {state => (
      <div className={`fade-number fade-number-${state}`} >
        {children}
      </div>
    )}
  </Transition>
);

export default FadeNumber;
