function Dots({total, done, isCurrentActive}) {

  const itemClassListGetter = (index) => {
    return (
      'dots__item ' +
      ((done > index) ? 'dots__item--done' : '') +
      ((index === done && isCurrentActive) ? 'dots__item--active' : '')
    );
  }

  return (
    <div className="dots">
      <div className="dots__list">
        {
          [...Array(total)].map((x, i) => {
            return (
              <div className={itemClassListGetter(i)} key={i}/>
            )
          })
        }
      </div>
    </div>
  );
}

export default Dots;
