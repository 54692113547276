import React, {useCallback} from 'react';
import cn from 'classnames';

import MedoedLeftBody from './../../assets/images/medoed-left.png';
import MedoedRightBody from './../../assets/images/medoed-right.png';
import LeftHand from './../../assets/images/hang-left.png';
import RightHand from './../../assets/images/hang-right.png';
import Countdown from "./Countdown";

const TwoMedoed = ({handsUp, onStartTry, startOnCountDown}) => {

  const onCountDownEnd = useCallback(() => {
    onStartTry();
  }, [onStartTry]);

  return (
    <>
      <div className={cn(['two-medoed-block', handsUp && 'two-medoed-block--hands-up'])}>
        <div className="medoed medoed--left">
          <div className="medoed__images">
            <img src={MedoedLeftBody} className="body" alt=""/>
            <img src={LeftHand} className="hand hand--left" alt=""/>
            <img src={RightHand} className="hand hand--right" alt=""/>
          </div>
        </div>
        <div className="medoed medoed--right">
          <div className="medoed__images">
            <img src={MedoedRightBody} className="body" alt=""/>
            <img src={LeftHand} className="hand hand--left" alt=""/>
            <img src={RightHand} className="hand hand--right" alt=""/>
          </div>
        </div>
      </div>
      <Countdown
        count={startOnCountDown}
        callback={onCountDownEnd}
      />
    </>
  );
}

export default TwoMedoed;
