import {getParentOfType, types} from 'mobx-state-tree';
import {guestStepList, regularStepList} from "./stepListData";
import {onNextStep, TestModel} from "../root";

export const StepItem = types.model({
  target: types.union(types.literal('child'), types.literal('adult')),
  cssClassSuffix: types.optional(types.string, '')
});

export const StepList = types.model({
  strategy: types.optional(types.enumeration(['guest', 'regular']), 'regular'),
  items: types.optional(types.array(StepItem), []),
  currentIndex: types.optional(types.number, 0)
}).actions(self => {
  function seed(stepData) {
    self.items = stepData;
  }

  function next(device) {
    const test = getParentOfType(self, TestModel);
    onNextStep(test, device);
    self.currentIndex += 1;
  }

  function afterCreate() {
    if (self.strategy === 'regular') {
      seed(regularStepList);
    } else {
      seed(guestStepList);
    }
  }

  return {
    next,
    afterCreate
  }
}).views(self => ({
  currentStepObject: () => {
    return self.items[self.currentIndex]
  }
}))
