function StepTarget({value}) {

  const classNameMap = {
    child: 'step-target--child',
    adult: 'step-target--adult'
  }

  const classList = ('step-target ' + (classNameMap.hasOwnProperty(value) ? classNameMap[value] : ''));

  const textMap = {
    child: 'Для детей',
    adult: 'Для родителей'
  }

  const text = textMap.hasOwnProperty(value) ? textMap[value] : '';

  return (
    <div className={classList}>
      <div className="step-target__wrapper">
        <div className="step-target__icon"/>
        <div className="step-target__text">
          {text}
        </div>
      </div>
    </div>
  );
}

export default StepTarget;
