import localStorage from 'mobx-localstorage';

export const isAuthenticated = () => {
  const token = localStorage.getItem('token') || null;
  const tokenExpiredAt = Number(localStorage.getItem('tokenExpiredAt')) || 0;

  if (!token || !tokenExpiredAt) {
    return false;
  }

  const now = Math.ceil(new Date().getTime() / 1000);

  return tokenExpiredAt > now;
}
