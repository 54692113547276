import axios from 'axios';
import localStorage from "mobx-localstorage";


class AuthApiService {
  commonHeaders = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  login = async ({email, password}) => {
    const requestData = {
      email,
      password,
    };

    try {
      const url = process.env.REACT_APP_LOGIN_API;

      const res = await axios.post(
        url,
        requestData,
        this.commonHeaders,
      );

      const {data} = res;

      const {success, access_token} = data;

      if (success !== true) {
        return false;
      }

      return {access_token};
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  register = async ({email, name, phone}) => {
    const requestData = {
      email,
      name,
      phone,
    };

    try {
      const url = process.env.REACT_APP_REGISTER_API;

      const res = await axios.post(
        url,
        requestData,
        this.commonHeaders,
      );

      const {data} = res;

      const {success, access_token} = data;

      if (success !== true) {
        return data;
      }

      return {access_token};
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  logout = async () => {
    try {
      const url = process.env.REACT_APP_LOGOUT_API;

      const token = localStorage.getItem('token');

      await axios.get(
        url,
        {
          ...this.commonHeaders,
          headers: {
            'Authorization': `Bearer ${token}`
          },
        },
      );
    } catch (e) {
      console.error(e);
    }

    return true;
  };
}

export default AuthApiService;
