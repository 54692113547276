import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import {Link} from "react-router-dom";
import SwiperCore, {Pagination} from 'swiper';
import {observer} from 'mobx-react';

import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import ImgFirstStep from './../../assets/images/img-first-step.svg';
import VideoPoster from './../../assets/images/video_poster.jpg';
import Play from './../../assets/images/play.svg';

import P1 from './../../assets/images/person-1.png';
import P2 from './../../assets/images/person-2.png';
import P3 from './../../assets/images/person-3.png';

import SP1 from './../../assets/images/sp-1.png';
import SP2 from './../../assets/images/sp-2.png';
import SP3 from './../../assets/images/sp-3.png';
import SP4 from './../../assets/images/sp-4.png';
import SP5 from './../../assets/images/sp-5.png';

import Q1 from './../../assets/images/q-1.jpg';
import Q2 from './../../assets/images/q-2.jpg';
import Q3 from './../../assets/images/q-3.jpg';
import Q4 from './../../assets/images/q-4.jpg';


import Q1m from './../../assets/images/Q1-m.jpg';
import Q2m from './../../assets/images/Q2-m.jpg';
import Q3m from './../../assets/images/Q3-m.jpg';
import Q4m from './../../assets/images/Q4-m.jpg';

import Q1s from './../../assets/images/Q1-s.jpg';
import Q2s from './../../assets/images/Q2-s.jpg';
import Q3s from './../../assets/images/Q3-s.jpg';
import Q4s from './../../assets/images/Q4-s.jpg';

import Res from './../../assets/images/res.png';
import D from './../../assets/images/icon_download.svg';
import ArrowRight from './../../assets/images/arrow-right.svg';
import ReactPlayer from "react-player";
import Header from "./Block/Header";
import Footer from "./Block/Footer";
import FeedBackApiService from "../../services/FeedBackApiService";
import {LK, NEW_TEST, POLICY} from "../../routes";
import {isAuthenticated} from "../../utils/isAuthenticated";

SwiperCore.use([Pagination]);

const IndexLandingPage = () => {
  const [playingVideoId, setPlayingVideoId] = useState('');

  const feedBackApiService = new FeedBackApiService();
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const onFeedBackSubmit = (event) => {
    event.preventDefault();

    if (!name.trim()) {
      alert('Укажите имя');
      return;
    }

    const clearPhone = phone.match(/\d/g).join("");
    if (clearPhone.length !== 11) {
      alert('Укажите номер телефона');
      return;
    }

    setPhone('');
    setName('');

    feedBackApiService.sendFeedback({name, phone});

    alert('С вами свяжется наш менеджер');

    return false;
  }

  return (
    <div className="landing-root">
      <div className="landingPage-wrapper landingPage-wrapper--header-padding">
        <div className="landingPage">
          <Header/>
          <div className="img-first-step">
            <img src={ImgFirstStep} alt=""/>
          </div>
          <div className="block-wrapper">
            <div className="block block-1" id="test">
              <h2>
                Тест на&nbsp;определение генетической предрасположенности<br/>
                ребенка к&nbsp;избранному виду спорта
              </h2>
              <div className="player-wrapper">
                <ReactPlayer
                  controls={true}
                  className="landing_video"
                  url="/videos/1-about-test.mp4"
                  playIcon={<img src={Play} alt="play"/>}
                  light={VideoPoster}
                  playing={playingVideoId === 'first'}
                  onClickPreview={() => {
                    setPlayingVideoId('first');
                  }}
                  onPlay={() => {
                    setPlayingVideoId('first');
                  }}
                />
              </div>
              <p>
                Все мы&nbsp;с&nbsp;вами знаем, что спорт является неотъемлемой частью развития ребенка. Спорт учит
                победам,
                проигрышам, уважению, дисциплине. Спорт делает ребенка крепким и&nbsp;физически развитым.
                Но&nbsp;инструмент
                будет работать тогда, когда ребенок попадает в&nbsp;свою среду.<br/><br/>
                В&nbsp;каждом ребенке с&nbsp;рождения заложены физические и&nbsp;психологические особенности, которые
                помогают ему достигать поставленных целей. Это, кстати, определение слова &laquo;талант&raquo;.
                И&nbsp;в&nbsp;свою очередь, вид спорта требует от&nbsp;людей ряда особенностей, чтобы они достигали
                успеха
                в&nbsp;выбанной нише.
              </p>
            </div>
          </div>
          <div className="block-wrapper">
            <div className="block block-2" id="trainers">
              <div className="trainer-block">
                <div className="trainer-item">
                  <div className="left">
                    <img src={P1} alt=""/>
                  </div>
                  <div className="right">
                    <h4>&laquo;Одна из&nbsp;главных задач тренера&nbsp;&mdash; найти талантливого ученика&raquo;</h4>
                    <p>
                      <b>Виктор Михайлович Кузнецов</b><br/>
                      Заслуженный тренер России, тренер Олимпийских чемпионов.<br/>
                      За&nbsp;всю свою карьеру тренера по&nbsp;борьбе из&nbsp;10&nbsp;000 учеников
                      он&nbsp;нашел только трёх.
                    </p>
                  </div>
                </div>
                <div className="trainer-item">
                  <div className="left">
                    <img src={P2} alt=""/>
                  </div>
                  <div className="right">
                    <h4>&laquo;Задача тренера&nbsp;&mdash; разглядеть талант&raquo;</h4>
                    <p>
                      <b>Владимир Федорович Ким</b><br/>
                      Тренер по&nbsp;тяжелой атлетике, воспитавший рекордсменов и&nbsp;чемпионов мира.
                      По&nbsp;словам Владимира Федоровича
                      &laquo;Задача тренера как раз и&nbsp;состоит в&nbsp;том, чтобы разглядеть талант ученика. Сегодня
                      большой спорт &mdash;
                      дело
                      исключительно таланта. Трудолюбие в&nbsp;спорте тоже ценится, но&nbsp;лишь для тренировки
                      собственной воли&raquo;.
                    </p>
                  </div>
                </div>
                <div className="trainer-item">
                  <div className="left">
                    <img src={P3} alt=""/>
                  </div>
                  <div className="right">
                    <h4>&laquo;У&nbsp;всякого ребенка есть определенный талант, нужно лишь его увидеть&raquo;</h4>
                    <p>
                      <b>Сальвадор Апаричио</b><br/>
                      Первый тренер Месси раскрыл его талант абсолютно случайно. Мать Лионеля не&nbsp;разрешала ему
                      ходить на
                      футбол, так как он&nbsp;был очень маленьким и&nbsp;слабым. Но&nbsp;однажды Сальвадор, который
                      тренировал братьев
                      Месси, не&nbsp;смог набрать команду из-за того, что один из&nbsp;мальчиков не&nbsp;пришел
                      на&nbsp;игру. Играть
                      поставили
                      Лионеля и&nbsp;уже в&nbsp;этой игре он&nbsp;заметил его врожденные навыки и&nbsp;настоял
                      на&nbsp;футбольной карьере.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-wrapper">
            <div className="block block-3">
              <h2>
                Тест поможет найти тот спорт, где ребенок будет
                успешен, который будет нравится, в&nbsp;котором будет преуспевать.
              </h2>
              <Link className="landing-button landing-button--blue" to={isAuthenticated() ? LK : NEW_TEST}>
                Сделать первый шаг <img src={ArrowRight} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="landingPage-wrapper landingPage-wrapper--white">
        <div className="landingPage landingPage--white">
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block-sportsman" id="sportsmans">
              <h2>
                Спортсмены, нашедшие свою нишу
              </h2>
            </div>
          </div>
          <div className="block-sportsman--flex">
            <div className="sportsman-item">
              <img src={SP1} alt=""/>
              <h4>Усейн Болт</h4>
              <b>Легкая атлетика</b>
              <p>
                Всем известно имя Усейна Болта. В&nbsp;детстве родители отвели его на&nbsp;секцию крикета,
                и&nbsp;он&nbsp;мечал стать лучшим
                в&nbsp;этом виде спорта. До&nbsp;тех пор, пока его тренер не&nbsp;настоял на&nbsp;переходе в&nbsp;легкую
                атлетику. Это было
                тяжелое решение со&nbsp;стороны Болта, но&nbsp;вы&nbsp;итоге он&nbsp;стал легендой спринта
                на&nbsp;100&nbsp;метров. В&nbsp;Книге спортивный
                ген сказано, тренера зацепила антропометрия Болта и&nbsp;мощный старт в&nbsp;беге. Кто знает,
                как&nbsp;бы сложилась его
                судьба, если&nbsp;бы не&nbsp;настойчивость тренера.
              </p>
            </div>
            <div className="sportsman-item">
              <img src={SP2} alt=""/>
              <h4>У&#769;ле-Эйнар Бьёрндален</h4>
              <b>Биатлон</b>
              <p>
                История Бьерндалена&nbsp;&mdash; история легенды биатлона. До&nbsp;биатлона занимался футболом,
                гандболом, метанием
                копья, велоспортом, легкой атлетикой. Показал результат только в&nbsp;биатлоне. Что было&nbsp;бы, если
                родители
                настояли на&nbsp;одной секции?
              </p>
            </div>
            <div className="sportsman-item">
              <img src={SP3} alt=""/>
              <h4>Майкл Фелпс</h4>
              <b>Плавание</b>
              <p>
                Присмотревшись к&nbsp;пловцу пристальнее, была обнаружена непропорциональность спортсмена, что также
                пошло
                для объяснения спортивного феномена. Действиетельно, при росте 193&nbsp;см Фелпс имеет размах рук
                в&nbsp;201&nbsp;см.
                Его торс необычайно длинен и&nbsp;тонок для пловца, в&nbsp;то&nbsp;время как ноги очевидно коротковаты.
                Еще у&nbsp;Фелпса
                был обнаружен чрезвычайно эластичный голеностоп, позволяющий ему выгибать ступню круче, чем балерины.
                Говорят, что Майкл потребляет каллорий в&nbsp;несколько раз больше, чем обычный человек его комплекции,
                а&nbsp;его
                детскими диагнозами были гиперактивность и&nbsp;импульсивность.
              </p>
            </div>
            <div className="sportsman-item">
              <img src={SP4} alt=""/>
              <h4>Майкл Джордан</h4>
              <b>Баскетбол</b>
              <p>
                С&nbsp;детства мечтал стать профессиональным игроком в&nbsp;бейсбол и&nbsp;даже в&nbsp;юношеские годы
                выигрывал на
                соревнованиях местного уровня, но&nbsp;по&nbsp;настоянию старшего брата увлекся баскетболом,
                по&nbsp;началу Майкл не
                показывал выдающихся результатов, параллельно занимаясь легкой атлетикой и&nbsp;американским футболом.
                Но&nbsp;все
                выбор пал на&nbsp;баскетбол, прилагая не&nbsp;мало усилий Джордан добился выдающихся результатов
                в&nbsp;этом спорте.
                Кстати по&nbsp;окончанию баскетбольной карьеры Майкл перешел в&nbsp;бейсбол, но&nbsp;никаких результатов
                там не
                показал.
              </p>
            </div>
            <div className="sportsman-item">
              <img src={SP5} alt=""/>
              <h4>Льюис Хэмилтон</h4>
              <b>Автоспорт</b>
              <p>
                Легенда автогонок и&nbsp;обладатель самого большого количества рекордов. С&nbsp;детства участвовал
                в&nbsp;соревнованиях
                гоночных машин, и&nbsp;достаточно успешно. Позже начал заниматься картингом, в&nbsp;юношеские годы
                занимался
                футболом, крикетом. По&nbsp;настоянию родителей пошел на&nbsp;каратэ, чтобы, по&nbsp;их&nbsp;словам,
                смог постоять за&nbsp;себя.
                Но&nbsp;большой интерес к&nbsp;автоспорту и&nbsp;результаты взяли вверх над другими дисциплинами.
                Он&nbsp;полностью
                погрузился в&nbsp;автогонки, а&nbsp;результат вы&nbsp;сами знаете.
              </p>
            </div>
          </div>
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block" id="test-structure">
              <h2>
                Структура теста
              </h2>
              <br/>
              <br/>
              <div className="player-wrapper">
                <ReactPlayer
                  controls={true}
                  className="landing_video"
                  url="/videos/2-test-scructure.mp4"
                  playIcon={<img src={Play} alt="play"/>}
                  light={VideoPoster}
                  playing={playingVideoId === 'second'}
                  onClickPreview={() => {
                    setPlayingVideoId('second');
                  }}
                  onPlay={() => {
                    setPlayingVideoId('second');
                  }}
                />
              </div>
              <p className="black">
                Тест состоит из&nbsp;ряда тестов и&nbsp;делится на&nbsp;две части: родительскую и&nbsp;детскую.<br/>
                <br/>
                В&nbsp;родительской части вам нужно будет ответить на&nbsp;ряд вопросов. Вопросы совпровождаются
                инструкцией к
                выполнению.<br/>
                <br/>
                В&nbsp;детской части вы&nbsp;вместе с&nbsp;детьми и&nbsp;нашими героями в&nbsp;игровой форме будете
                выполнять задания. Вам
                понадобится:<br/>
                1) рулетка для измерения длины прыжка,<br/>
                2) палка или жеское полотенце для определения гибкости.<br/>
                <br/>
                После прохождения теста, через некоторое время, вам будут доступны результаты: на&nbsp;сколько процентов
                и
                какой вид спорта подходит вашему ребенку. Результаты доступны личном кабинете
                и&nbsp;в&nbsp;виде PDF-файла.
              </p>
              <br/>
              <br/>
              <h2>
                Детская часть разработана в&nbsp;формате игр, это сделано для того, чтобы монотонные но&nbsp;работающие
                тесты
                сделать интересными и&nbsp;вовлечь ребенка в&nbsp;процесс тестирования.
              </h2>
            </div>
          </div>
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block block-before-steps">
              <h2>
                Дальнейшие шаги
              </h2>
            </div>
            <div className="block-steps">
              <div className="step-item">
                <b>01</b>
                <span>Попробуйте пройти с&nbsp;ребенком первую игру на&nbsp;задержку дыхания</span>
              </div>
              <div className="step-item">
                <b>02</b>
                <span>Зарегистрируйтесь или войдите в&nbsp;личный кабинет, оплатите тест</span>
              </div>
              <div className="step-item">
                <b>03</b>
                <span>Пройдите все шаги в&nbsp;родительской и&nbsp;детской части</span>
              </div>
              <div className="step-item">
                <b>04</b>
                <span>Посмотрите результат в&nbsp;личном кабинете или скачайте PDF-файл</span>
              </div>
            </div>
          </div>
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block block-5">
              <Link className="landing-button landing-button--blue" to={isAuthenticated() ? LK : NEW_TEST}>
                Сделать первый шаг <img src={ArrowRight} alt=""/>
              </Link>
            </div>
          </div>

          <div className="block-wrapper block-wrapper--no-border">
            <div className="block block-6" id="know">
              <h2>Важно знать!</h2>
              <p className="black">Перед тем как перейти к&nbsp;результатам важно знать, еще о&nbsp;нескольких
                критериях, которые
                могут повлиять на
                выбор спортивной дисциплины, а&nbsp;также, которые могут влиять на&nbsp;тренировочный процесс. Средни
                них &mdash;
                возраст, с&nbsp;которого нужно начинать; средний возраст чемпионов в&nbsp;спорте; рейтинг российского
                спорта на
                международной арене; стоимость занятий и&nbsp;экипировки; травматичность; перспективы спорта
                и&nbsp;особенности
                спортсменов.<br/>
                <br/>
                Такую информацию по&nbsp;видам спорта вы&nbsp;получите вместе с&nbsp;результатами тестирования.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="landingPage-wrapper">
        <div className="landingPage">
          <div className="block-wrapper block-wrapper--no-border how-look-test">
            <div className="block" id="test-view">
              <h2>Как выглядит тест</h2>
            </div>
            <div className="q-images q-images--b">
              <img src={Q1} alt=""/>
              <img src={Q2} alt=""/>
              <img src={Q3} alt=""/>
              <img src={Q4} alt=""/>
            </div>
            <div className="q-images q-images--m">
              <img src={Q1m} alt=""/>
              <img src={Q2m} alt=""/>
              <img src={Q3m} alt=""/>
              <img src={Q4m} alt=""/>
            </div>
            <div className="q-images q-images--s">

              <Swiper
                slidesPerView={1}
                loop={true}
                pagination={{clickable: true}}
              >
                <SwiperSlide>
                  <img src={Q1s} alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Q2s} alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Q3s} alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Q4s} alt=""/>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="block" id="results">
              <h2>Результат теста</h2>
              <div className="res-block">
                <div className="left">
                  <img src={Res} alt=""/>
                </div>
                <div className="right">
                  <p>
                    Результаты теста содержат следующую информацию:<br/>
                    &bull; Результаты прохождения каждого упражения<br/>
                    &bull; На&nbsp;сколько процентов и&nbsp;какой вид спорта подходит вашему ребенку<br/>
                    &bull; Дополнительная информация о&nbsp;видах спорта.<br/><br/>

                    Результаты доступны в&nbsp;личном кабинете и&nbsp;в&nbsp;виде PDF-файла.
                  </p><br/>
                  <br/><br/>
                  <div className="landing-button landing-button--blue">
                    <a href="/demo/SilaSkilla_Test_Demo.pdf" target="_blank" download="SilaSkilla_Test_Demo.pdf"
                    >
                      <img src={D} alt=""/> Скачать DEMO-файл
                    </a>
                  </div>
                  <br/>
                  <br/>
                  <Link className="landing-button landing-button--blue" to={isAuthenticated() ? LK : NEW_TEST}>
                    Сделать первый шаг <img src={ArrowRight} alt=""/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landingPage-wrapper landingPage-wrapper--white" id="contact">
        <div className="landingPage landingPage--white">
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block">
              <h2>
                Остались вопросы?
              </h2>
              <form className="feedback" onSubmit={onFeedBackSubmit}>
                <p className="black">
                  Заполните форму, и&nbsp;наш менеджер свяжется с&nbsp;вами
                </p>
                <div className="input-group">
                  <label htmlFor="feedback-name">Ваше имя</label>
                  <input type="text" id="feedback-name" name="name" value={name} onChange={onNameChange}/>
                </div>
                <InputMask mask="+7 (999) 999 99 99" value={phone} onChange={onChangePhone}>
                  {(inputProps) => (
                    <div className="input-group">
                      <label htmlFor="feedback-phone">Телефон</label>
                      <input type="tel"{...inputProps} id="feedback-phone" name="phone"/>
                    </div>
                  )}
                </InputMask>
                <div
                  className="landing-button landing-button--blue landing-button--width-210"
                  onClick={onFeedBackSubmit}
                >
                  Перезвоните мне
                </div>
                <i className="policy">
                  Нажимая на&nbsp;кнопку &laquo;Перезвоните мне&raquo;, я&nbsp;даю согласие<br/>
                  на&nbsp;обработку <Link to={POLICY}>персональных данных</Link>
                </i>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default observer(IndexLandingPage);
