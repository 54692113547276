import {useEffect} from 'react';
import {observer} from 'mobx-react';

import {
  StepNav
} from '../core';

function FlexStep({test}) {

  const {flexibilitystep, steplist} = test;

  useEffect(() => {
    flexibilitystep.init();
    flexibilitystep.setCallback(() => {
      steplist.next();
    });
  }, []);

  return (
    <div className="plain-step plain-step--flex">
      <div className="plain-step__container plain-step__container--flex">
        <div className="plain-step__title">
          Упражнение на гибкость
        </div>
        <div className="plain-step__subtitle">
          Выполните два упражнения:<br/>
          <br/>
          1. Возьмите палку, такой длинны, чтобы ухватив&nbsp;ее, руки были шире плеч. Поднимите палку над головой как
          можно выше, и&nbsp;сдвиньте руки чуть дальше линии плеч назад.
          <br/><br/>
          2. Сядьте на&nbsp;пол и&nbsp;выпрямите ноги перед собой. Носки вытяните и&nbsp;направьте к&nbsp;себе. Голени
          прижмите к&nbsp;поверхности пола. Сделайте наклон вперед как можно сильнее. Возвращаясь в&nbsp;ровное
          положение, не&nbsp;делайте резкий движений.
          <br/><br/>
          Выберите подходящий вариант.
        </div>
        <div className="plain-step__card-picker">
          <div className="card-picker card-picker--flex">
            <div className="card-picker__list">
              {
                flexibilitystep.flexibilityList.map((item, index) => {
                  const cssClassList = ('card-picker__item card-picker__item--' + item.id)
                  return (
                    <div
                      className={cssClassList}
                      key={index}
                      onClick={() => flexibilitystep.setFlexibility(item.id)}
                    >
                      <div className="card-picker__image"/>
                      <div className="card-picker__image-2"/>
                      <div className="card-picker__title">
                        {item.title}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="plain-step__step-nav">
        <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
      </div>
    </div>
  );
}

export default observer(FlexStep);
