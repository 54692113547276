import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';

import {hydrateTest, TestModel, useMst} from '../../models/root';

import {
  NameStep,
  AgeStep,
} from '../steps';

import {
  StepTarget,
} from '../core';
import {Link, useHistory, useParams} from "react-router-dom";

import cn from 'classnames';
import {isAuthenticated} from "../../utils/isAuthenticated";
import TestApiService from "../../services/TestApiService";
import {INDEX, LK} from "../../routes";
import Loader from "react-loader-spinner";

import BreathStep from "../steps/breath-step";
import PaymentStep from "../steps/payment-step";
import InGameLoginPage from "../auth/InGameLoginPage";
import ParentsStep from "../steps/parents-step";
import GenderStep from "../steps/gender-step";
import ConstitutionStep from "../steps/constitution-step";
import PsychotypeStep from "../steps/psychotype-step";
import JumpStep from "../steps/jump-step";
import CnsStep from "../steps/cns-step";
import CoordinationStep from "../steps/coordination-step";
import ReactionStep from "../steps/reaction-step";

import {guestStepList} from "../../models/core/stepListData";
import FoxHelloStep from "../steps/fox-hello-step";
import FlexStep from "../steps/flex-step";
import FinalStep from "../steps/final-step";

function TestPage() {
  const [pending, setPending] = useState(true);
  let {testId} = useParams();
  const testApiService = new TestApiService()
  const store = useMst();
  const history = useHistory();
  const loggedIn = isAuthenticated();

  useEffect(() => {
    setPending(true);
    if (testId === 'new') {
      if (!loggedIn) {
        const stepList = {
          currentIndex: 0,
          strategy: 'guest',
          items: guestStepList,
        };

        store.setTest(TestModel.create({
          steplist: stepList,
        }));
      } else {
        store.setTest(TestModel.create());
      }
      setPending(false);
    } else {
      try {
        testApiService.getById({testId})
          .then(
            (testFromApi) => {
              if (!testFromApi) {
                if (loggedIn) {
                  history.push(LK);
                  return;
                }

                history.push(INDEX);
              }

              store.setTest(hydrateTest(testFromApi));

              setPending(false);
            },
            () => {
              if (loggedIn) {
                history.push(LK);
                return;
              }

              history.push(INDEX);
            }
          );
      } catch (e) {
        console.error(e);
        history.push(INDEX);
      }
    }
  }, [testId]);

  if (pending || !store.hydrated) {
    return (
      <div className="app app--fullscreen-loading">
        <Loader
          className="loader"
          type="Oval"
          color="#fdb913"
          height={40}
          width={40}
        />
        <Link className="app__logo" to="/"/>
      </div>
    );
  }

  const {test} = store;

  const {steplist} = test;
  const {strategy} = steplist;
  const currentStepObject = steplist.currentStepObject();

  let isPaymentPage = false;
  if (!test.payedManually) {
    isPaymentPage =
      (strategy === 'regular' && loggedIn && !test.payed && steplist.currentIndex === 2)
      || (strategy === 'guest' && loggedIn && !test.payed && steplist.currentIndex === 3);
  }

  const testPaid = test.payedManually || test.payed;

  return (
    <div
      className={cn([
        'app',
        currentStepObject && currentStepObject.cssClassSuffix && `app${currentStepObject.cssClassSuffix}`
      ])}
    >
      {strategy === 'guest' && (
        <>
          {steplist.currentIndex === 0 && (
            <AgeStep test={test}/>
          )}
          {steplist.currentIndex === 1 && (
            <BreathStep test={test}/>
          )}
          {steplist.currentIndex === 2 && (
            <NameStep test={test}/>
          )}
          {!loggedIn && steplist.currentIndex === 3 && (
            <InGameLoginPage/>
          )}
          {loggedIn && !testPaid && steplist.currentIndex === 3 && (
            <PaymentStep test={test}/>
          )}
          {loggedIn && testPaid && steplist.currentIndex === 3 && (
            <ParentsStep test={test}/>
          )}
          {steplist.currentIndex === 4 && (
            <GenderStep test={test}/>
          )}
          {steplist.currentIndex === 5 && (
            <ConstitutionStep test={test}/>
          )}
          {steplist.currentIndex === 6 && (
            <PsychotypeStep test={test}/>
          )}
          {steplist.currentIndex === 7 && (
            <FoxHelloStep test={test}/>
          )}
          {steplist.currentIndex === 8 && (
            <JumpStep test={test}/>
          )}
          {steplist.currentIndex === 9 && (
            <CoordinationStep test={test}/>
          )}
          {steplist.currentIndex === 10 && (
            <FlexStep test={test}/>
          )}
          {steplist.currentIndex === 11 && (
            <CnsStep test={test}/>
          )}
          {steplist.currentIndex === 12 && (
            <ReactionStep test={test}/>
          )}
          {steplist.currentIndex === 13 && (
            <FinalStep test={test}/>
          )}
        </>
      )}
      {strategy === 'regular' && (
        <>
          {steplist.currentIndex === 0 && (
            <AgeStep test={test}/>
          )}
          {steplist.currentIndex === 1 && (
            <NameStep test={test}/>
          )}
          {!loggedIn && steplist.currentIndex === 2 && (
            <InGameLoginPage/>
          )}
          {loggedIn && !testPaid && steplist.currentIndex === 2 && (
            <PaymentStep test={test}/>
          )}
          {loggedIn && testPaid && steplist.currentIndex === 2 && (
            <ParentsStep test={test}/>
          )}
          {steplist.currentIndex === 3 && (
            <GenderStep test={test}/>
          )}
          {steplist.currentIndex === 4 && (
            <ConstitutionStep test={test}/>
          )}
          {steplist.currentIndex === 5 && (
            <PsychotypeStep test={test}/>
          )}
          {steplist.currentIndex === 6 && (
            <FoxHelloStep test={test}/>
          )}
          {steplist.currentIndex === 7 && (
            <BreathStep test={test}/>
          )}
          {steplist.currentIndex === 8 && (
            <JumpStep test={test}/>
          )}
          {steplist.currentIndex === 9 && (
            <CoordinationStep test={test}/>
          )}
          {steplist.currentIndex === 10 && (
            <FlexStep test={test}/>
          )}
          {steplist.currentIndex === 11 && (
            <CnsStep test={test}/>
          )}
          {steplist.currentIndex === 12 && (
            <ReactionStep test={test}/>
          )}
          {steplist.currentIndex === 13 && (
            <FinalStep test={test}/>
          )}
        </>
      )}
      <Link className="app__logo" to="/"/>
      <div className="app__step-target">
        <StepTarget value={isPaymentPage ? 'adult' : currentStepObject.target}/>
      </div>
    </div>
  );
}

export default observer(TestPage);
