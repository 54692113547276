import {types} from 'mobx-state-tree';
import getRandomRanged from '../../../utils/getRandomRanged';

export const HareListItem = types.model({
  state: 'idle',
  timeshown: types.optional(types.maybeNull(types.number), null),
  timeclicked: types.optional(types.maybeNull(types.number), null),
}).actions(self => {
  function spawn() {
    self.state = 'active';
    self.timeshown = +(new Date());
  }

  function click() {
    self.state = 'clicked';
    self.timeclicked = +(new Date());
  }

  return {
    spawn,
    click
  }
});

export const HareList = types.model({
  items: types.optional(types.array(HareListItem), [])
})
  .views(self => ({
    get hasIdleHares() {
      return self.items.filter((hare) => hare.state === 'idle').length > 0;
    },
    get hasUnclickedHares() {
      return self.items.filter((hare) => hare.state !== 'clicked').length > 0;
    },
    get clickedCount() {
      return self.items.filter((hare) => hare.state !== 'clicked').length;
    }
  }))
  .actions(self => {

    // function editHare(index, newProps) {
    // 	self.items = self.items.map((item, innerIndex) => {
    // 		if(innerIndex !== index) {
    // 			return item;
    // 		} else {
    // 			return {...item, ...newProps}
    // 		}
    // 	})
    // }

    function setCallback(callback) {
      self.callback = callback;
    }

    function spawnAtIndex(index) {
      self.items[index].spawn()
    }

    function clickAtIndex(index) {
      self.items[index].click()

      // if (!self.hasUnclickedHares) {
        // один заяц не появиться, игру кончаем раньше
      if (self.clickedCount === 1) {
        self.callback(self.items);
      }
    }

    function spawnRandom() {
      const indexes = [];
      self.items.forEach((item, index) => {
        if (item.state === 'idle') indexes.push(index);
      });

      if (indexes.length > 0) {
        const spawnedIndex = indexes[getRandomRanged(0, indexes.length - 1)];

        spawnAtIndex(spawnedIndex);
      }
    }

    function initHareList() {
      const newItems = [];
      for (let i = 0; i < 9; i++) {
        newItems.push(HareListItem.create())
      }

      self.items = newItems;
    }

    return {
      setCallback,
      clickAtIndex,
      spawnAtIndex,
      spawnRandom,
      initHareList
    }
  })
