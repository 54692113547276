import Loader from "react-loader-spinner";

function AppButton({text, buttonType, disabled = false, onClick, pending = false}) {

  const classNameMap = {
    solid: 'button--solid',
    outline: 'button--outline'
  }
  const classList = ('button ' + (classNameMap.hasOwnProperty(buttonType) ? classNameMap[buttonType] : ''));

  const onClickWrapper = () => {
    if (!disabled) {
      onClick();
    }
  }

  return (
    <button
      className={classList}
      onClick={onClickWrapper}
      disabled={disabled}
    >
      {pending && (
        <Loader
          className="loader"
          type="Oval"
          color="#000"
          height={20}
          width={20}
        />
      )}
      {!pending && text}
    </button>
  );
}

export default AppButton;
