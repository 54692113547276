function UserName({name, onClick}) {
	return (
		<div className="step-target--adult step-target--name" onClick={onClick}>
			<div className="step-target__wrapper">
				<div className="step-target__icon"/>
				<div className="step-target__text">
					{ name }
				</div>
			</div>
		</div>
	);
}

export default UserName;
