import {types} from 'mobx-state-tree';

export const ParentsStep = types.model({
  fatherHeight: types.optional(types.string, ''),
  motherHeight: types.optional(types.string, '')
}).actions(self => {

  function setFatherHeight(newValue) {
    self.fatherHeight = newValue;
  }

  function setMotherHeight(newValue) {
    self.motherHeight = newValue;
  }

  return {
    setFatherHeight,
    setMotherHeight
  }
}).views(self => ({
  get stepValid() {

    return (!!self.fatherHeight && parseInt(self.fatherHeight, 10) > 0 && !!self.motherHeight && parseInt(self.motherHeight, 10) > 0);
  }
}))
