import React from 'react';
import {observer} from 'mobx-react';

import {TestModel} from '../../models/root';

import {
  StepTarget,
} from '../core';
import {Link} from "react-router-dom";
import CnsStep from "../steps/cns-step";

function CnsSingleGamePage() {
  const emptyTest = TestModel.create();

  return (
    <div className="app">
      <CnsStep test={emptyTest}/>
      <Link className="app__logo" to="/"/>
      <div className="app__step-target">
        <StepTarget value="child"/>
      </div>
    </div>
  );
}

export default observer(CnsSingleGamePage);
