import { types } from 'mobx-state-tree';

export const AgeStep = types.model({
	age: types.optional(types.string, '')
}).actions(self => {

	function setAge(newValue) {
		self.age = newValue;
	}

	return {
		setAge
	}
}).views(self => ({
	get ageValid() {
     if (!self.age.length) {
     	return false;
		 }

     const ageNumber = Number(self.age);

     if (ageNumber < 6 || ageNumber > 10) {
     	return false;
		 }

     return true;
	}
}))
