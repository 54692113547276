import { observer } from 'mobx-react';
import HareholeItem from './harehole-item';

const HareholeGame = ({test}) => {

	const { reactionstep: { game } } = test;

	const onHareClickedWrapper = (index) => {
		game.harelist.clickAtIndex(index);
	}

	return (
		<div className="haregame">
			<div className="haregame__list">
				{
					game.harelist.items.map((hare, index) => {

						const onHareItemClicked = () => onHareClickedWrapper(index);

						return (
							<div className="haregame__item" key={index}>
								<HareholeItem
									state={hare.state}
									onClick={onHareItemClicked}
								/>
							</div>
						)
					})
				}
			</div>
		</div>
	);
}

export default observer(HareholeGame);
