const tryNTimes = (times = 3, conditionFn, callbackFn, success, fail) => {
	let i = 0;
	while(i < times && !conditionFn()) {
		callbackFn();
		if(conditionFn()) {
			success();
			return;
		}
		i++;
		if(i === times && !conditionFn()) fail();
	}
}

export default tryNTimes;