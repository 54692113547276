import React from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import cn from 'classnames';
import {AppButton} from "./index";
import Countdown from "react-countdown";
import plural from 'plural-ru';
import {useHistory} from "react-router-dom";
import {
  ORDER_STATUS_APPROVED,
  ORDER_STATUS_AUTHORIZATION_INITIALIZED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_DEPOSITED
} from "../../orderStatuses";
import TestApiService from "../../services/TestApiService";
import {TEST} from "../../routes";


const LkTestRow = ({test}) => {
  const isFinished = test.isFinished;
  const history = useHistory();
  const testApiService = new TestApiService();

  const finishedStepCount = filter(test.stepsState, (step) => step === 1).length;
  const totalStepCount = test.stepsState.length;

  const shouldPay = test.paidManually === 0 && test.paymentStatus === ORDER_STATUS_CREATED;
  const waitPaymentConfirm = test.paidManually === 0 && test.paymentStatus === ORDER_STATUS_AUTHORIZATION_INITIALIZED;
  const payed = test.paidManually === 1 || test.paymentStatus === ORDER_STATUS_DEPOSITED || test.paymentStatus === ORDER_STATUS_APPROVED;

  const timer = (test) => ({hours, minutes, seconds, completed, ...rest}) => {
    if (completed) {
      return (
        <AppButton
          buttonType="solid"
          text="Скачать PDF"
          onClick={() => {
            testApiService.download(test.resultLink);
            // window.location.href = test.resultLink;
          }}
        />
      );
    }

    let text = '';

    if (hours !== 0) {
      text = `${hours} ${plural(parseInt(hours), 'час', 'часа', 'часов')}`;
    } else if (minutes !== 0) {
      text = `${minutes} ${plural(parseInt(minutes), 'минуту', 'минуты', 'минут')}`;
    } else if (seconds !== 0) {
      text = `${parseInt(seconds)} ${plural(parseInt(seconds), 'секунду', 'секунды', 'секунд')}`;
    }

    return (
      <>
        <div className="result-status">
          Ждём результаты
        </div>
        <div className="result-timer">
          Будет готово через {text}
        </div>
      </>
    );
  };

  return (
    <div className={cn(['lk-test-row', !isFinished && 'lk-test-row--accent'])}>
      <div className="left">
        <div className="name">{test.name}</div>

        <div className="gender-age">
          {test.sex && (
            `${test.sex === 1 ? 'Мальчик' : ''}${test.sex === 2 ? 'Девочка' : ''}`
          )}
          {test.sex && test.age && `, `}
          {test.age && `${test.age} лет`}
        </div>
      </div>
      <div className="center">
        <div className="test-status">
          {test.isFinished && 'Тест завершен'}
          {!test.isFinished && `${finishedStepCount}/${totalStepCount} шагов`}
        </div>
        <div className="step-status">
          {map(test.stepsState, (step, index) => (
            <div key={index}
                 className={cn(['step-block', step === 1 && 'step-block--finished'])}
            />
          ))}
        </div>
      </div>
      <div className="right">
        {!test.isFinished && shouldPay && (
          <AppButton
            buttonType="solid"
            text="Перейти к оплате"
            onClick={() => {
              testApiService.download(test.resultLink);
              window.location.href = test.paymentLink;
            }}
          />
        )}
        {!test.isFinished && payed && (
          <AppButton
            buttonType="solid"
            text="Продолжить"
            onClick={() => {
              history.push(`${TEST}/${test.id}`)
            }}
          />
        )}
        {!test.isFinished && waitPaymentConfirm && (
          <span>
            Ожидаем когда оплата пройдет
          </span>
        )}
        {payed && test.isFinished && test.canDownloadResult && (
          <AppButton
            buttonType="solid"
            text="Скачать PDF"
            onClick={() => {
              testApiService.download(test.resultLink);
              window.location.href = test.resultLink;
            }}
          />
        )}
        {payed && test.isFinished && !test.canDownloadResult && (
          <Countdown
            date={Date.now() + test.resultTimer * 1000}
            renderer={timer(test)}
          />
        )}
      </div>
    </div>
  );
}

export default LkTestRow;
