import { types } from 'mobx-state-tree';
import { HareList } from './hare-list';

export const HareholeGame = types.model({
	active: false,
	harelist: types.optional(HareList, {})
}).actions(self => {

	let localTimeout;

	function init() {
		self.harelist.initHareList();
	}

	function gameCycle() {
		self.harelist.spawnRandom();
		localTimeout = setTimeout(() => {
			if(self.harelist.hasIdleHares && self.active) {
				gameCycle();
			}
		}, 3000);
	}

	function start() {
		self.active = true;

		gameCycle();
	}

	function stop() {
		self.active = false;

		clearTimeout(localTimeout);
	}

	return {
		init,
		start,
		stop
	}
})