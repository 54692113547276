import {observer} from 'mobx-react';

import {
  AppButton,
  StepNav
} from '../core';
import ArrowRight from "../../assets/images/arrow-right.svg";


function FoxHelloStep({test}) {
  const {namestep, steplist} = test;

  const goToNextStep = () => {
    test.setFoxHello(true);
    steplist.next();
  }

  return (
    <>
      <div className="fox-gym"/>
      <div className="plain-step">
        <div className="plain-step__container">
          <div className="fox-hello-popup">
            <div className="content">
              {namestep?.name && (
                <h2>Привет, {namestep.name}!</h2>
              )}
              <p>
                Нас ждёт увлекательное путешествие. Мы и поиграем,<br/>
                и сделаем несколько упражнений.<br/>
                Давай начнём!<br/>
              </p>
              <AppButton
                buttonType="solid"
                text={<>Следующий шаг <img src={ArrowRight} alt=""/></>}
                onClick={goToNextStep}
              />
            </div>
          </div>

        </div>
        <div className="plain-step__step-nav">
          <div className="big-fox"/>
          <StepNav stepsLength={steplist.items.length} stepActive={steplist.currentIndex}/>
        </div>
      </div>
    </>
  );
}

export default observer(FoxHelloStep);
