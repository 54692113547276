import { observer } from 'mobx-react';
import stringifyMs from '../../utils/stringifyMs';

const PlumberGame = ({test}) => {
	const { cnsstep: { game } } = test;

	const onClickedWrapper = (rowIndex, colIndex) => {
		game.rotateTube(rowIndex, colIndex);
	}

	return (
		<div className="plumber">
			<div className="plumber__container">
				<div className="plumber__gui">
					<div className="plumber__info">
						<div className="plumber__info-label">
							Шаги
						</div>
						<div className="plumber__info-value">
							{ game.moves }
						</div>
					</div>
					<div className="plumber__info">
						<div className="plumber__info-label">
							Время
						</div>
						<div className="plumber__info-value">
							{ stringifyMs(game.durationShown) }
						</div>
					</div>
				</div>
				<div className="plumber__gamearea">
					{
						game.tubes.map((tubeRow, rowIndex) => {
							return (
								<div className="plumber__row" key={rowIndex}>
									{
										tubeRow.map((tube, colIndex) => {
											const cssClassList = (
												('tube') +
												(tube ? (' tube--shift-' + tube.shiftLevel) : '') +
												(tube ? (' tube--type-' + tube.type) : '') +
												(tube && tube.active ? ' tube--active' : '')
											)
											return (
												<div className="plumber__col" key={colIndex}>
													<div
														onClick={() => onClickedWrapper(rowIndex, colIndex)}
														className={cssClassList}
													/>
												</div>
											)
										})
									}
								</div>

							)
						})
					}
				</div>
			</div>
		</div>
	);
}

export default observer(PlumberGame);
