import React, {useEffect, useState} from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import {useHistory} from "react-router-dom";
import {observer} from 'mobx-react';
import Loader from "react-loader-spinner";
import {isAuthenticated} from "../../utils/isAuthenticated";
import LkHeader from "../core/LkHeader";
import LkTestRow from "../core/lk-test-row";
import {AppButton} from "../core";
import TestApiService from "../../services/TestApiService";
import {
  ORDER_STATUS_APPROVED,
  ORDER_STATUS_AUTHORIZATION_INITIALIZED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_DEPOSITED
} from "../../orderStatuses";
import {NEW_TEST, INDEX} from "../../routes";

const mapDataToComponent = (tests) => {
  const normTests = filter(
    tests,
    (test) => test.payment_status === ORDER_STATUS_CREATED
      || test.payment_status === ORDER_STATUS_APPROVED
      || test.payment_status === ORDER_STATUS_DEPOSITED
      || test.payment_status === ORDER_STATUS_AUTHORIZATION_INITIALIZED
      || test.paid_manually === 1
  );

  return map(normTests, (test) => {
    // "age": 10,
    // "sex": null,
    // "name": "adsasd",
    // "mother_height": null,
    // "father_height": 4234,
    // "anthropometry": null,
    // "psychology": null,
    // "breath_holding_time": null,
    // "time_to_find_a_solution": null,
    // "jump_length": null,
    // "reaction_time": null,
    // "flexibility": null,
    // "some_coordination": nul

    let stepsState = [];
    if (test.game_type === 'regular') {
      stepsState = [
        !test.age ? 0 : 1,
        !test.name ? 0 : 1,
        (!test.mother_height || !test.father_height) ? 0 : 1,
        !test.sex ? 0 : 1,
        !test.anthropometry ? 0 : 1,
        !test.psychology ? 0 : 1,
        // знакомство с персонажем
        !test.breath_holding_time ? 0 : 1,
        !test.jump_length ? 0 : 1,
        !test.some_coordination ? 0 : 1,
        !test.flexibility ? 0 : 1,
        !test.time_to_find_a_solution ? 0 : 1,
        !test.reaction_time ? 0 : 1,
      ];
    } else {
      stepsState = [
        !test.age ? 0 : 1,
        !test.breath_holding_time ? 0 : 1,
        !test.name ? 0 : 1,
        (!test.mother_height || !test.father_height) ? 0 : 1,
        !test.sex ? 0 : 1,
        !test.anthropometry ? 0 : 1,
        !test.psychology ? 0 : 1,
        // знакомство с персонажем
        !test.jump_length ? 0 : 1,
        !test.some_coordination ? 0 : 1,
        !test.flexibility ? 0 : 1,
        !test.time_to_find_a_solution ? 0 : 1,
        !test.reaction_time ? 0 : 1,
      ];
    }

    return {
      id: test.id,
      age: test.age,
      name: test.name,
      sex: test.sex,
      stepsState,
      paidManually: test.paid_manually,
      paymentStatus: test.payment_status,
      paymentLink: test.payment_link,
      isFinished: !!test.completed_at,
      resultTimer: test.download_timer,
      resultLink: test.download_link,
      canDownloadResult: !!test.completed_at && test.download_timer && test.download_timer <= 0,
    }
  })
};

const LKPage = () => {
  const history = useHistory();

  const loggedIn = isAuthenticated();

  useEffect(() => {
    if (!loggedIn) {
      history.push(INDEX);
    }
  }, [history, loggedIn]);

  const [pending, setPending] = useState(false);
  const [tests, setTests] = useState([]);
  const testApiService = new TestApiService();

  // let updateTimeout = null;

  // useEffect(() => {
  //   setInterval(() => {
  //     testApiService.list().then((tests) => {
  //       setTests(mapDataToComponent(tests));
  //     });
  //   }, 10000);
  //
  //   return () => {
  //     if(updateTimeout) {
  //       clearInterval(updateTimeout);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    testApiService.list().then((tests) => {
      setTests(mapDataToComponent(tests));
      setPending(false);
    });
    setPending(true);
  }, []);

  const onNewTestClick = () => {
    history.push(NEW_TEST);
  }

  const unFinishedTests = filter(tests, test => !test.isFinished);
  const finishedTests = filter(tests, test => test.isFinished);

  return (
    <div className="app app--system">
      <LkHeader/>
      <div className="lk-content">
        <div className="plain-step__title">Мои тесты</div>
        {pending && (
          <div className="big-loader">
            <Loader
              type="Oval"
              color="#fdb913"
              height={40}
              width={40}
            />
          </div>
        )}
        {!pending && (
          <>
            {unFinishedTests.length > 0 && (
              <div className="lk-content__un-finished">
                <div className="lk-content__title">У вас есть не завершенные тесты</div>
                {map(unFinishedTests, (test) => (
                  <LkTestRow
                    key={test.id}
                    test={test}
                  />
                ))}
              </div>
            )}
            {finishedTests.length > 0 && (
              <div className="lk-content__finished">
                <div className="lk-content__title">
                  Завершенные тесты
                </div>
                {map(finishedTests, (test) => (
                  <LkTestRow
                    key={test.id}
                    test={test}
                  />
                ))}
              </div>
            )}
          </>
        )}
        <div className="lk-content__start_new">
          <AppButton
            buttonType="solid"
            text="Пройти новый тест"
            onClick={onNewTestClick}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(LKPage);
