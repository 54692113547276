import { useState } from 'react';

function HareholeItem({state, onClick}) {

	const classNameMap = {
		idle: 'harehole--idle',
		active: 'harehole--active',
		clicked: 'harehole--clicked',
		gone: 'harehole--gone',
	}
	const classList = ('harehole ' + (classNameMap.hasOwnProperty(state) ? classNameMap[state] : ''));

	const onClickWrapper = () => {
		onClick();
	}

	return (
		<div className={classList}>
			<div className="harehole__hole"/>
			<div className="harehole__hare" onClick={onClickWrapper}/>
		</div>
	);
}

export default HareholeItem;
