import axios from 'axios';
import localStorage from "mobx-localstorage";


class ProfileApiService {
  commonHeaders = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  headers = () => {
    const token = localStorage.getItem('token');

    return {
      ...this.commonHeaders,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }
  }

  get = async () => {
    try {
      const url = process.env.REACT_APP_PROFILE_API;

      const response = await axios.get(
        url,
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return true;
  };

  update = async ({name, phone, email}) => {
    const requestData = {
      name,
      phone,
      email,
    };

    try {
      const url = process.env.REACT_APP_PROFILE_API;

      const response = await axios.post(
        url,
        requestData,
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return false;
  };
}

export default ProfileApiService;
