import {useEffect, useState, useCallback} from 'react';
import {observer} from 'mobx-react';
import cn from 'classnames';

import stringifyMs from '../../utils/stringifyMs';

import {
  AppButton,
  Dots,
  StepNav
} from '../core';
import HoldBreath from "../core/hold-breath";

function BreathStep({test}) {
  const {breathStep: {game, trylist}, steplist} = test;

  useEffect(() => {
    game.init();
    game.setCallback(() => {
      trylist.push({duration: +(new Date()) - game.timeStarted});
    });
  }, []);

  const [startOnCountDown, setStartOnCountDown] = useState(false);

  const onStartTry = useCallback(() => {
    setStartOnCountDown(false);
    game.start();
  }, [setStartOnCountDown, game])

  const toggleGame = () => {
    if (!game.active) {
      setStartOnCountDown(true);
    } else {
      game.stop();
    }
  };

  const getStateName = () => {

    if (game.active) {
      return 'playing';
    } else {
      if (trylist.items.length === 0) {
        return 'clean'
      } else if (trylist.items.length === trylist.max) {
        return 'finished';
      } else {
        return 'break';
      }
    }
  }
  const stateName = getStateName();

  const rootClassNameMap = {
    playing: 'step--playing',
    clean: 'step--clean',
    finished: 'step--finished',
    break: 'step--break',
  }
  const rootClassList = ('step step--breath ' + (rootClassNameMap.hasOwnProperty(stateName) ? rootClassNameMap[stateName] : ''));

  const getStatusName = () => {
    if (stateName === 'break') {
      return 'Засечь время';
    } else if (stateName === 'playing') {
      return 'Стоп';
    } else {
      return 'Засечь время';
    }
  }

  // const playButtonText = (stateName === 'break') ? 'Еще разок' : 'Начать игру';
  const playButtonText = getStatusName();
  const playButtonDisabled = (stateName === 'finished' || startOnCountDown);
  const nextStepButtonMode = (stateName === 'finished') ? 'solid' : 'outline';
  const nextStepButtonDisabled = !(stateName === 'finished');

  const goToNextStep = () => {
    if (!nextStepButtonDisabled) {
      steplist.next();
    }
  }

  return (
    <div className={cn([rootClassList, startOnCountDown && 'step--in-countdown-state'])}>
      <div className="panel panel--breath">
        <div className="panel__wrapper">
          <div className="panel__body">
            <div className="panel__title">
              Упражнение на задержку дыхания
            </div>
            <div className="panel__text">
              Рекомендуется отдохнуть 2-3&nbsp;минуты.
              Сидя сделать средней силы три вдоха и&nbsp;выдоха,
              а&nbsp;на&nbsp;высоте четвертого вдоха закрыть рот
              и&nbsp;задержать дыхание, зажав нос пальцами руки.
            </div>
            <div className="panel__text panel__text--highlighted">
              Чем дольше задерживаешь дыхание —<br/>
              тем дольше медведь погружается в воду!
            </div>
            {game.durationShown > 0 && (
              <div className="panel__result panel__result--current">
                <div className="panel__result-label">
                  Текущий результат
                </div>
                <div className="panel__result-value">
                  {game.durationShown > 0 && (
                    stringifyMs(game.durationShown)
                  )}
                </div>
              </div>
            )}
            <div className="panel__button-wrapper">
              <AppButton
                buttonType="solid"
                text={playButtonText}
                onClick={toggleGame}
                disabled={playButtonDisabled}
              />
            </div>
            <div className="panel__dots">
              <div className="panel__dots-label">
                Попытки
              </div>
              <div className="panel__dots-component">
                <Dots
                  total={trylist.max}
                  done={trylist.items.length}
                  isCurrentActive={game.active}
                />
              </div>
            </div>
            {
              stateName && (
                <div className="panel__text panel__text--highlighted panel__text--finished">
                  Молодец! Упражнение выполнено!<br/>
                  Переходи к следующему шагу.
                </div>
              )
            }
          </div>
          <div className="panel__footer">
            <div className="panel__result">
              <div className="panel__result-label">
                Лучший результат
              </div>
              <div className="panel__result-value">
                {trylist.bestResult && (
                  stringifyMs(trylist.bestResult.duration)
                )}
              </div>
            </div>
            <div className="panel__button-wrapper">
              <AppButton
                buttonType={nextStepButtonMode}
                text="Следующий шаг"
                onClick={goToNextStep}
                disabled={nextStepButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="step__step-nav">
        <StepNav
          stepsLength={steplist.items.length}
          stepActive={steplist.currentIndex}
        />
      </div>
      <div className="step__playground">
        <HoldBreath
          hold={game.active || startOnCountDown}
          startOnCountDown={startOnCountDown}
          onStartTry={onStartTry}
        />
      </div>
    </div>
  );
}

export default observer(BreathStep);
