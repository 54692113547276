import { types } from 'mobx-state-tree';

export const JumpStep = types.model({
	best: types.optional(types.string, '')
}).actions(self => {

	function setBest(newValue) {
		self.best = newValue;
	}

	return {
		setBest
	}
}).views(self => ({
	get bestValid() {

		return !!self.best;
	}
}))