import React from 'react';
import {observer} from 'mobx-react';

import {TestModel} from '../../models/root';

import {
  StepTarget,
} from '../core';
import {Link} from "react-router-dom";
import {
  NameStep,
  AgeStep,
} from '../steps';
import BreathStep from "../steps/breath-step";
import PaymentStep from "../steps/payment-step";
import InGameLoginPage from "../auth/InGameLoginPage";
import ParentsStep from "../steps/parents-step";
import GenderStep from "../steps/gender-step";
import ConstitutionStep from "../steps/constitution-step";
import PsychotypeStep from "../steps/psychotype-step";
import JumpStep from "../steps/jump-step";
import CnsStep from "../steps/cns-step";
import CoordinationStep from "../steps/coordination-step";
import ReactionStep from "../steps/reaction-step";
import PaymentSuccess from "../steps/payment-success";

function DevelopGame() {
  const emptyTest = TestModel.create();

  return (
    <div className="app app--step-jump">

      <JumpStep test={emptyTest} />

      <Link className="app__logo" to="/"/>
      <div className="app__step-target">
        <StepTarget value="child"/>
      </div>
    </div>
  );
}

export default observer(DevelopGame);
