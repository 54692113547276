import React from 'react';
import {observer} from 'mobx-react';

import {TestModel} from '../../models/root';

import {
  StepTarget,
} from '../core';
import {Link} from "react-router-dom";
import ReactionStep from "../steps/reaction-step";

function ReactionSingleGamePage() {
  const emptyTest = TestModel.create();

  return (
    <div className="app">
      <ReactionStep test={emptyTest}/>
      <Link className="app__logo" to="/"/>
      <div className="app__step-target">
        <StepTarget value="child"/>
      </div>
    </div>
  );
}

export default observer(ReactionSingleGamePage);
