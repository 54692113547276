import axios from 'axios';
import localStorage from "mobx-localstorage";


class TestApiService {
  commonHeaders = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  headers = () => {
    const token = localStorage.getItem('token');

    return {
      ...this.commonHeaders,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }
  }

  list = async () => {
    // {
    //   "success": true,
    //   "tests": [
    //   {
    //     "id": 8,
    //     "created_at": "2021-04-08T09:02:31.000000Z",
    //     "updated_at": "2021-04-15T16:32:11.000000Z",
    //     "mother_height": null,
    //     "father_height": 4234,
    //     "name": "adsasd",
    //     "age": 10,
    //     "sex": null,
    //     "anthropometry": null,
    //     "psychology": null,
    //     "breath_holding_time": null,
    //     "time_to_find_a_solution": null,
    //     "jump_length": null,
    //     "reaction_time": null,
    //     "flexibility": null,
    //     "some_coordination": null,
    //     "user_id": 4,
    //     "completed_at": null,
    //     "is_sent": 0,
    //     "download_link": "asdsad",
    //     "download_timer": 0,
    //     "payment_status": 0,
    //     "payment_link": "https://3dsec.sberbank.ru/payment/merchants/sbersafe_sberid/payment_ru.html?mdOrder=7bcc81c6-bb3d-71f4-bf0c-bd885e49898a"
    //   }
    // ]
    // }
    try {
      const url = process.env.REACT_APP_TEST_LIST_API;

      const response = await axios.get(
        url,
        {
          ...this.headers()
        },
      );

      const {data} = response;
      const {tests} = data;

      return tests;
    } catch (e) {
      console.error(e);
    }

    return true;
  };

  buyAsGuest = async ({name, age, breathHoldingTime}) => {
    const requestData = {
      name,
      age,
      breath_holding_time: breathHoldingTime,
      game_type: 'guest'
    };

    try {
      const url = process.env.REACT_APP_TEST_BUY_API;

      const response = await axios.post(
        url,
        requestData,
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  buy = async ({name, age}) => {
    const requestData = {
      name,
      age,
    };

    try {
      const url = process.env.REACT_APP_TEST_BUY_API;

      const response = await axios.post(
        url,
        requestData,
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  update = async ({testId, params}) => {
    try {
      const url = `${process.env.REACT_APP_TEST_UPDATE_API}/${testId}`;

      const response = await axios.patch(
        url,
        params,
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  complete = async ({testId}) => {
    try {
      const url = `${process.env.REACT_APP_TEST_COMPLETE_API}/${testId}`;

      const response = await axios.post(
        url,
        {},
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  // REACT_APP_TEST_UPDATE_API
  // REACT_APP_TEST_COMPLETE_API

  getById = async ({testId}) => {
    try {
      const url = `${process.env.REACT_APP_TEST_GET_ONE_API}/${testId}`;

      const response = await axios.get(
        url,
        {
          ...this.headers()
        },
      );

      const {data} = response;

      return data;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  download = async ({donwloadLink}) => {
    try {
      await axios.get(
        donwloadLink,
        {
          ...this.headers()
        },
      );

      // return data;
    } catch (e) {
      console.error(e);
    }

    // return false;
  };

}

export default TestApiService;
