import React, {useState, useRef, useEffect} from 'react';
import useOutsideClick from "@rooks/use-outside-click";
import cn from 'classnames';
import {isAuthenticated} from "../../utils/isAuthenticated";
import UserName from "./user-name";
import {Link} from "react-router-dom";
import LockYellowSvg from './../../assets/images/lock-yellow.svg';
import SettingsSvg from './../../assets/images/settings.svg';
import ExitSvg from './../../assets/images/exit.svg';
import localStorage from "mobx-localstorage";
import AuthApiService from "../../services/AuthApiService";
import {useMst} from "../../models/root";

const LkHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalPerson, setShowModalPerson] = useState(false);
  const store = useMst();

  const onLinkClick = () => {
    setShowModal(false);
    setShowModalPerson(false);
  }

  const toggleShowModal = () => {
    setShowModal(!showModal);
    setShowModalPerson(false);
  }

  const toggleShowPersonModal = () => {
    setShowModal(false);
    setShowModalPerson(!showModalPerson);
  }

  const pRef = useRef();
  const pRef2 = useRef();

  const hideModal = () => {
    setShowModal(false);
  }

  const hidePersonalModal = () => {
    setShowModalPerson(false);
  }

  const authApiService = new AuthApiService();

  const onLogout = async () => {
    await authApiService.logout();
    store.clearProfile();

    localStorage.setItem('token', '');
    localStorage.setItem('tokenExpiredAt', '');
  }

  useEffect(() => {
    window.addEventListener('resize', hideModal);
    window.addEventListener('resize', hidePersonalModal);

    return () => {
      window.removeEventListener('resize', hideModal);
      window.removeEventListener('resize', hidePersonalModal);
    }
  }, [])

  useOutsideClick(pRef, hideModal);
  useOutsideClick(pRef2, hidePersonalModal);

  return (
    <div className="lk-header-wrapper">
      <div className="header" ref={pRef}>
        <div className="left">
          <Link className="header__logo" to="/"/>
        </div>
        <div className="right" ref={pRef2}>
          {isAuthenticated() && (
            <>
              <UserName name={store.profile?.name} onClick={toggleShowPersonModal}/>
              <div className={cn(['menu', 'menu--person', showModalPerson && 'menu--show-modal'])}>
                <Link
                  className="menu__item"
                  to={'/profile'}
                  onClick={onLinkClick}
                >
                  <img src={SettingsSvg} alt=""/> Настройки
                </Link>
                <div
                  className="menu__item"
                  onClick={onLogout}
                >
                  <img src={ExitSvg} alt=""/> Выйти
                </div>
              </div>
              <div className="burger" onClick={toggleShowModal}/>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default LkHeader;
