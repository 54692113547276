import { types } from 'mobx-state-tree';

export const NameStep = types.model({
	name: types.optional(types.string, '')
}).actions(self => {

	function setName(newValue) {
		self.name = newValue;
	}

	return {
		setName
	}
}).views(self => ({
	get nameValid() {

		return !!self.name;
	}
}))