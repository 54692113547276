import React from 'react';
import Footer from "./Block/Footer";
import Header from "./Block/Header";

const About = () => {
  return (
    <div className="landing-root">
      <div className="landingPage-wrapper">
        <div className="landingPage">
          <Header/>
          <div className="block-wrapper block-wrapper--no-border">
            <div className="block">
              <h2>Контакты</h2>
              <p>
                630007, Новосибирск, ул. Коммунистическая дом 34<br/>
                Электронная почта: konanbarbarian72@gmail.com<br/>
                Телефон 8-952-902-18-04<br/>
              </p>
              <h2>Реквизиты</h2>
              <p>
                ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ СЕЛАНТЬЕВ ДМИТРИЙ СЕРГЕЕВИЧ<br/>
                Расчётный счёт: 40802810044050038518<br/>
                ИНН: 380414362163<br/>
                КПП: -<br/>
                ОГРН: 319547600168453<br/>
                Банк: СИБИРСКИЙ БАНК ПАО СБЕРБАНК<br/>
                БИК: 045004641<br/>
                Кор. счёт: 30101810500000000641
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default About;
