import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import localStorage from 'mobx-localstorage';
import {observer} from 'mobx-react';
import get from 'lodash/get';

import {isAuthenticated} from "../../utils/isAuthenticated";
import InputMask from "react-input-mask";
import validateEmail from "../../utils/validateEmail";
import Tabs from "../core/Tab/Tabs";
import Tab from "../core/Tab/Tab";
import TabContent from "../core/Tab/TabContent";
import AuthApiService from "../../services/AuthApiService";
import {AppButton, StepTarget} from "../core";
import {POLICY} from "../../routes";


const InGameLoginPage = () => {
  const history = useHistory();
  const loggedIn = isAuthenticated();

  const [pending, setPending] = useState(false);

  const authApiService = new AuthApiService();

  useEffect(() => {
    if (loggedIn) {
      history.push('/lk');
    }
  }, [history, loggedIn]);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  }
  const onLoginChange = (event) => {
    setLogin(event.target.value);
  }

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const onRegistrationFormSubmit = async () => {
    if (pending) {
      return false;
    }

    setPending(true);

    if (!name.trim()) {
      alert('Укажите имя');
      setPending(false);
      return;
    }

    if (!validateEmail(email)) {
      alert('Укажите email');
      setPending(false);
      return;
    }

    const clearPhone = phone.match(/\d/g).join("");
    if (clearPhone.length !== 11) {
      alert('Укажите номер телефона');
      setPending(false);
      return;
    }

    const result = await authApiService.register({
      email,
      name,
      phone: clearPhone,
    })

    const hasEmailErrors = get(result, 'errors.email');

    if (hasEmailErrors) {
      alert('Такой email уже занят');
      setPending(false);

      return false;
    }

    const accessToken = result.access_token;

    localStorage.setItem('token', accessToken);
    localStorage.setItem('tokenExpiredAt', String(Math.ceil(new Date().getTime()) + 60 * 60 * 24 * 30)); // 1 месяц

    setPending(false);
    return false;
  }

  const onLoginFormSubmit = async () => {
    if (pending) {
      return false;
    }

    setPending(true);

    if (!login.trim()) {
      alert('Укажите e-mail');
      setPending(false);

      return;
    }

    if (!password.trim()) {
      alert('Укажите пароль');
      setPending(false);

      return;
    }

    let result = false;

    try {
      result = await authApiService.login({email: login, password});

    } catch (e) {
      console.error(e);
    }

    if (result === false) {
      setPending(false);
      alert('Не правильный логин или пароль');

      return false;
    }

    const accessToken = result.access_token;

    localStorage.setItem('token', accessToken);
    localStorage.setItem('tokenExpiredAt', String(Math.ceil(new Date().getTime()) + 60 * 60 * 24 * 30)); // 1 месяц

    setPending(false);
    return false;
  }

  return (
    <div className="app app--system app--system--center">
      <div className="login-form">
        <div className="plain-step__title">Войдите, чтобы продолжить</div>
        <Tabs initialTab={0}>
          <div className="tabs-wrapper">
            <Tab index={0}>
              Регистрация нового<br/>
              пользователя
            </Tab>
            <Tab index={1}>
              У меня уже<br/>
              есть аккаунт
            </Tab>
          </div>
          <div className="tabs-content-wrapper">
            <TabContent index={0}>
              <p>Заполните данные, мы&nbsp;создадим вам аккаунт для доступа к&nbsp;результатам теста и&nbsp;в&nbsp;личный
                кабинет.</p>
              <div className="registration-form">
                <div className="input-group input-group--white">
                  <label htmlFor="reg-name">ФИО</label>
                  <input type="text" id="reg-name" name="password" value={name} onChange={onNameChange}/>
                </div>

                <div className="input-group input-group--white">
                  <label htmlFor="reg-email">E-mail</label>
                  <input type="text" id="reg-email" name="password" value={email} onChange={onEmailChange}/>
                </div>

                <InputMask mask="+7 (999) 999 99 99" value={phone} onChange={onChangePhone}>
                  {(inputProps) => (
                    <div className="input-group input-group--white">
                      <label htmlFor="reg-phone">Телефон</label>
                      <input type="tel"{...inputProps} id="reg-phone" name="phone"/>
                    </div>

                  )}
                </InputMask>

                <br/>
                <AppButton
                  disabled={pending}
                  pending={pending}
                  buttonType="solid"
                  text="Создать аккаунт"
                  onClick={onRegistrationFormSubmit}
                />
                <i className="policy">
                  Нажимая на&nbsp;кнопку &laquo;Создать аккаунт&raquo;, я&nbsp;даю согласие<br/>
                  на&nbsp;обработку <Link to={POLICY}>персональных данных</Link>
                </i>
              </div>
            </TabContent>
            <TabContent index={1}>
              <p>Войдите, если у вас уже есть
                аккаунт</p>
              <div>
                <div className="input-group input-group--white">
                  <label htmlFor="login-email">E-mail</label>
                  <input type="text" id="login-email" name="email" value={login} onChange={onLoginChange}/>
                </div>

                <div className="input-group input-group--white">
                  <label htmlFor="login-password">Пароль</label>
                  <input type="password" id="login-password" name="password" value={password}
                         onChange={onPasswordChange}/>
                </div>

                <br/>
                <AppButton
                  disabled={pending}
                  pending={pending}
                  buttonType="solid"
                  text="Войти"
                  onClick={onLoginFormSubmit}
                />
              </div>
            </TabContent>
          </div>
        </Tabs>
      </div>
      <Link className="app__logo" to="/"/>
      <div className="app__step-target">
        <StepTarget value="adult" />
      </div>
    </div>
  );
}

export default observer(InGameLoginPage);
