import { types } from 'mobx-state-tree';

export const BreathGame = types.model({
	active: false,
	durationShown: types.optional(types.number, 0),
	timeStarted: types.optional(types.number, 0)
})
	.actions(self => {

	let localTimeout;

	function setCallback(callback) {
		self.callback = callback;
	}

	function init() {
	}

	function setTime() {
		self.timeStarted = +(new Date());
	}

	function updateDurationShown() {
		self.durationShown = +(new Date()) - self.timeStarted;
	}

	function timerCycle() {
		self.updateDurationShown();
		localTimeout = setTimeout(() => {
			if(self.active) timerCycle()
		})
	}

	function start() {
		self.active = true;
		setTime();
		timerCycle();
	}

	function stop() {
		self.active = false;
		clearInterval(localTimeout);
		if(self.callback) self.callback();
	}

	return {
		updateDurationShown,
		setCallback,
		init,
		start,
		stop
	}
})
