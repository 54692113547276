import { types } from 'mobx-state-tree';

const Gender = types.model({
	id: types.optional(types.string, ''),
	text: types.optional(types.string, ''),
})

export const GenderStep = types.model({
	selectedGender: types.optional(types.maybeNull(Gender), null),
	genders: types.optional(types.array(Gender), [])
}).actions(self => {

	function loadGenders(callback) {
		callback([
			{id: '1', text: 'Мальчик'},
			{id: '2', text: 'Девочка'},
		])
	}

	function init() {
		loadGenders((data) => {
			self.genders = data;
		})
	}

	function setGender(genderId) {
		const foundGender = self.genders.filter((gender) => gender.id === genderId)[0];
		self.selectedGender = Gender.create({...foundGender});

		if(self.callback) self.callback(self.selectedGender);
	}

	function setCallback(callback) {
		self.callback = callback;
	}

	return {
		init,
		setGender,
		setCallback
	}
}).views(self => ({
	get bestValid() {

		return !!self.best;
	}
}))